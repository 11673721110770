//020721
// Value Study Component

import {useState, useRef, useEffect} from 'react'
import OptionPosition from '../../classes/Option'
import CompositeValueGraph from '../graphs/CompositeValueGraph'
import OptionPositionList from '../OptionPositionList' 
import ExtrinsicValue from '../graphs/ExtrinsicValueGraph'
import ValueOverTimeGraph from '../graphs/ValueOverTimeGraph'
import ValueOverTimeROCGraph from '../graphs/ROC_ValueOverTimeGraph'
import GreeksGraphs from '../graphs/GreeksGraph'

function ValueStudy() {
    // Component containing an OptionPositionsList and PLGraph
    const [positions, setPositions] = useState([new OptionPosition()]);

    return (
        <div>
            <h3>Value Study</h3>
            <OptionPositionList listId="valuestudy-positions" onPositionsUpdateCallback={setPositions}/>
            <GreeksGraphs optionPositions={positions}/>
            <CompositeValueGraph optionPositions={positions}/>
            <ExtrinsicValue optionPositions={positions}/>
            <ValueOverTimeGraph optionPositions={positions}/>
            <ValueOverTimeROCGraph optionPositions={positions}/>
            
        </div>
    );
}

export default ValueStudy;