/**110421
 * ImpliedVolatility.js
 * Module to calculate IV given an option price
 */


    //Approach 1 (incremental search):
    //if testPrice < marketPrice, 
    //  add volModifier to v, 
    //  check again.
    //  If testPrice still < marketPrice, repeat
    //  Continue this until testPrice > marketPrice
    //      check error, if error > initError, then undo last addition, decrease  volMod, add again, and check
    //      Continue this until testPrice>marketPrice and error<initError
    //  

    //=====================================
    
    //Approach 2 (percent-change-based search):
    // find percentage change from testPrice to marketPrice
    // modify v by that percent, retest

    // let pctPriceDiff = (testPrice-marketPrice)/testPrice;
    // v = v * pctPriceDiff;
    // testPrice = bs.blackScholes(underlyingPrice, strike, t, v, riskFreeRate, callPut);
    // return v;

    //======================================

    // Update: Approach 3 works.
    //Approach 3 (vega-based incremental search): 
    // check vega of init testPrice.. divide error (testPrice-marketPrice) by vega, that should give you how many 1% increases are needed to vol in order to reach marketPrice
 

// TODO: implement this into Option.OptionMethods class
// inv ncfd formula: https://corporatefinanceinstitute.com/resources/excel/functions/norm-inv-function/

 import * as bs from 'black-scholes'
//  import {cdf} from 'norm-dist' 
 import {OptionMethods} from '../classes/Option'
 import OptionPosition from '../classes/Option'

export default function getIV(underlyingPrice, strike, daysToExpiration, riskFreeRate, callPut, marketPrice, initIV=0.5,maxError=0.005) {

    //modify vol until bs returns given marketPrice
    let buySell="buy" //for creation of OptionPosition obj
    let v = initIV;

    let pos = new OptionPosition(underlyingPrice,buySell, callPut, strike,daysToExpiration,v,riskFreeRate);
    let error = marketPrice - pos.getPremium();

    let n = 0;
    while(Math.abs(error)>maxError ) { //&& n<50
        if(n%500==0 && (maxError+(maxError/0.001))<=0.01 ) { //maxError-(maxError/0.1)>0.01
            // v = 0.5
            // maxError = maxError+(maxError/0.001);
        }
        pos.volatility =v;
        let vega = pos.getVega();
        let vegaDiff = parseFloat((error/vega));
        let vegaPct = vega*0.01 // get 1% of vega
        v = v + (vegaPct*vegaDiff);
        error = marketPrice-pos.getPremium();
        
        //todo: try decreasing max error after n runs to decrease chance of while hang.

        //TODO: try checking if error is larger after last iteration.. if so, reset to last v value and adjust v diff, or vega pct
        // console.log('getIV()>',{ pos,initIV, marketPrice, 'premium':pos.getPremium(),vega,vegaDiff,v, n, maxError});
        n=n+1;
    }
    return v;   
}



// export function getIV_2(underlyingPrice, strike, daysToExpiration, riskFreeRate, callPut, optionPremium, histVol) { //041422 - from GPT3 - Looks like it's BS formula
//     const d1 = (Math.log(underlyingPrice / strike) + (riskFreeRate + 0.5 * Math.pow(0.01 * histVol, 2)) * daysToExpiration) / (0.01 * histVol * Math.sqrt(daysToExpiration));
//     const d2 = d1 - 0.01 * histVol * Math.sqrt(daysToExpiration);
  
//     if (callPut === 'call') {
//       return cdf(d1) * Math.exp((-1 * riskFreeRate * daysToExpiration) / 365) - cdf(d2) * Math.exp((-1 * riskFreeRate * daysToExpiration) / 365) - optionPremium;
//     } else if (callPut === 'put') {
//       return -cdf(-d1) * Math.exp((-1 * riskFreeRate * daysToExpiration) / 365) + cdf(-d2) * Math.exp((-1 * riskFreeRate * daysToExpiration) / 365) - optionPremium;
//     }
//   }
  
