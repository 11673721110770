import {useState} from 'react'
import GridLayout from 'react-grid-layout';
import Responsive from 'react-grid-layout';
import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ComposedChart } from 'recharts';
import GraphControlsUI from './GraphControlsUI'
import './DynamicGraph.css'
import graphTypes from '../../util/dataUtil'



export default function DynamicGraph({graphState, updateGraphCallback}) {

    const xAxis = graphState.activeAxis.x;
    const yAxis = graphState.activeAxis.y;
    const zAxis = graphState.activeAxis.z;

    const updateGraphState = (args, remove=false) => { //todo: recursively deconstruct args
        updateGraphCallback({...graphState, ...args}, remove)
    }

    const updateActiveAxis = (args) => {
        updateGraphState({activeAxis: {...graphState.activeAxis, ...args}})
    }

    const updateGraphsGridLayout = (args) => {
        updateGraphState({gridLayout: {...graphState.gridLayout, ...args}})
    }


    const getAxisOptionDropdownElement = (id, value, callback) => {
        //
        return (
            <span className="dropdown preventDrag">
                <button className="btn dropdown-toggle axis-btn text-primary" type='button' id={id} data-bs-toggle="dropdown" aria-expanded="false">{value}</button>
                <ul className="dropdown-menu" aria-labelledby={id}>
                    {Object.keys(graphState.axisOptions).map((i)=> {
                        let classes = "dropdown-item";
                        if(i==value) classes+=" active";
                        return (<li className={classes}  onClick={callback} key={i} id={i} >{i}</li>)
                    })}
                </ul>
            </span>
        )
    }

    const getInteractiveTitleElement = () => {
        if(graphState.graphType == graphTypes.custom){
            const xAxisTitle = getAxisOptionDropdownElement('xAxisDropdown', xAxis, (e)=>updateActiveAxis({x: e.target.id}));
            const yAxisTitle = getAxisOptionDropdownElement('yAxisDropdown', yAxis, (e)=>updateActiveAxis({y: e.target.id}));
            return (
                <div className="section-title">{yAxisTitle} over {xAxisTitle}</div>
            )
        } else if(graphState.graphType == graphTypes.scatter3d || graphState.graphType == graphTypes.surface3d) {
            const xAxisTitle = getAxisOptionDropdownElement('xAxisDropdown', xAxis, (e)=>updateActiveAxis({x: e.target.id}));
            const yAxisTitle = getAxisOptionDropdownElement('yAxisDropdown', yAxis, (e)=>updateActiveAxis({y: e.target.id}));
            const zAxisTitle = getAxisOptionDropdownElement('zAxisDropdown', zAxis, (e)=>updateActiveAxis({z: e.target.id}));
            return (
                <div className="section-title">X: {xAxisTitle} Y:{yAxisTitle} Z: {zAxisTitle}</div>
            )
        }
    }


    const getGraphNavbar = () => { //todo: extract this into it's own component
        return (

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">

                    <div className="dropdown preventDrag">
                    <button className="btn navbar-brand dropdown-toggle" href="#" id="navbarGraphTypeDropdown" role="button" data-bs-toggle="dropdown">{graphState.graphType.name}</button>
                        <ul className="dropdown-menu" aria-labelledby="navbarGraphTypeDropdown">
                                {Object.keys(graphTypes).map((i)=>{
                                    return (<li className="dropdown-item" key={i} id={i} onClick={(e)=>updateGraphState({graphType: graphTypes[i]})}>{graphTypes[i].name}</li>)
                                })}
                        </ul>
                    </div>
                    <button className="navbar-toggler preventDrag" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        {/* <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li> */}

                        <li className="nav-item dropdown preventDrag">
                            <button className="btn nav-link dropdown-toggle" href="#" id="navbarSettingsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Settings
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="navbarSettingsDropdown">
                                <li><GraphControlsUI graphControlSettings={graphState.graphControlSettings} settingsModifiedCallback={(newSettings)=>updateGraphState({graphControlSettings: {...newSettings}})}/>
                                </li>
                            </ul>  
                        </li>

                        <li className="nav-item d-inline-flex position-absolute end-0">
                            {/* Couldn't get static to work in the grid, so disabling this switch for now */}
                            {/* <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="lockGraphSwitch" checked={graphState.gridLayout.static} onChange={(e)=>updateGraphsGridLayout({static: Boolean(e.target.checked)})}/>
                                <label class="form-check-label" for="lockGraphSwitch">Lock</label>
                            </div> */}
                            <button className="nav-item btn btn-close preventDrag" onClick={()=>updateGraphState({},true)}></button>
                        </li>

                    </ul>
                    </div>
                </div>
            </nav>
        )
    }
//width="100%" height="70%"
    return (
        <>
            {getGraphNavbar()}
            {getInteractiveTitleElement()}
            {/* <div className="container h-100 w-100"> */}
                <ResponsiveContainer width="100%" height="70%" className="mr-auto">
                    {graphState.graphType.getChart(graphState)}
                </ResponsiveContainer>
            {/* </div> */}
        </>
    )
}