/**
 * 041322 Futures
 * 
 * 
 */

import { ResponsiveContainer } from "recharts"
import DynamicGraph from "../graphs/DynamicGraph"
import { getTradingViewWidget } from "../../util/dataUtil"

export function Futures() {
    //todo: put each category of futures symbol in it's own collapsable section
    //todo: add universal range selector (to change timeframe)

    const futuresSymbols = { //todo: finish all futures categories.
        'US Index': ['YM1!','ES1!','NQ1!', 'RTY1!'],
        'Risk': ['VXX'],
        'Energy': ['CL1!', 'QA1!', 'RB1!', 'HO1!', 'NG1!','Zk1!']

    }


    return (
        <div className='container'>
            <div className='row flex-nowrap'>
                
                {Object.keys(futuresSymbols).map((k)=>{
                    return (<div className='col'>
                        <h3 className='row d-flex justify-content-center'>{k}</h3>
                            <div className='row'>
                            {futuresSymbols[k].map((symbol)=>{
                                const graphState = {data:{symbol}}
                                return <div className='col m-0 p-0' style={{width:'100%', height:'400px'}} >{getTradingViewWidget(graphState)}</div> //style={{'viewport-height':'50', 'viewport-width':'50'}}
                                })}
                            </div>  
                    </div>)
                })}
            </div>
        </div>
        

        
    )
}