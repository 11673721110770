// 021421
// Graphs to display greeks

// graph ideas:
//  -composite greeks over time?
//  -greeks over strikes

import { useState } from 'react';
import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ComposedChart } from 'recharts';
import GraphControlsUI from './GraphControlsUI'
import GraphDataSelector from './GraphDataSelectors'


function getNetGreeksOverTimeData(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10, adjustForPositionType=true) {
    // returns graph data for net greeks over time
    if(optionsData.length==0) return [];
    const data = []
    const lowerBound = 0;
    const upperBound = parseFloat(optionsData.reduce((max, o) => o.daysToExpiration > max ? o.daysToExpiration : max, optionsData[0].daysToExpiration));
  
    for(var i=upperBound + parseFloat(relUpperBound); i>=lowerBound; i-=dataIncrements) {
        let netGreeks = {"delta": 0, "gamma": 0, "vega": 0, "theta": 0, "rho": 0};
        for(let opt of optionsData) {
            let posGreeks = opt.getGreeks({daysToExpiration: i}, adjustForPositionType); 
            for(let g in posGreeks) {netGreeks[g]+=posGreeks[g]}
        }
        data.push({dte: i, ...netGreeks});
    }
    return data;
  }


function GreeksGraphs({optionPositions}) {

    const [graphControlSettings,setGraphControlSettings] = useState({bounds: 15, granularity: 1});

    let data = getNetGreeksOverTimeData(optionPositions, graphControlSettings.granularity, graphControlSettings.bounds, graphControlSettings.bounds);


  return (
    <div className="">
      {/*JSON.stringify(optionPositions)*/}
      <div className="container">
        <h3 className="section-title">Greeks Graph</h3>
        <div className="row align-items-center">

          {/* <div className="col"> */}
            <ResponsiveContainer width="50%" height={250} className="col-sm">
              <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
                <Tooltip />
                <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
                <YAxis />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
                <Line type="monotone" dataKey="delta" name="Delta" stroke="#1a472a" strokeWidth={1} dot={false}/>
                {/* <Line type="monotone" dataKey="gamma" name="Gamma" stroke="#2a623d" strokeWidth={1} dot={false}/>
                <Line type="monotone" dataKey="theta" name="Theta" stroke="#107dac" strokeWidth={1} dot={false}/>
                <Line type="monotone" dataKey="vega" name="Vega" stroke="#800000" strokeWidth={1} dot={false}/>
                <Line type="monotone" dataKey="rho" name="Rho" stroke="#6f4f1d" strokeWidth={1} dot={false}/> */}
                {/* <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/> */}
              </ComposedChart>
            </ResponsiveContainer>
          {/* </div> */}

          {/* <div className="col"> */}
          <ResponsiveContainer width="50%" height={250} className="col-sm">
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Line type="monotone" dataKey="gamma" name="Gamma" stroke="#2a623d" strokeWidth={1} dot={false}/>
              {/* <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/> */}
            </ComposedChart>
          </ResponsiveContainer>
          {/* </div> */}

          <ResponsiveContainer width="50%" height={250} className="col-sm">
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Line type="monotone" dataKey="theta" name="Theta" stroke="#107dac" strokeWidth={1} dot={false}/>
              {/* <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/> */}
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="50%" height={250} className="col-sm">
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Line type="monotone" dataKey="vega" name="Vega" stroke="#800000" strokeWidth={1} dot={false}/>
              {/* <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/> */}
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="50%" height={250} className="col-sm">
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Line type="monotone" dataKey="rho" name="Rho" stroke="#6f4f1d" strokeWidth={1} dot={false}/>
              {/* <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/> */}
            </ComposedChart>
          </ResponsiveContainer>

          {/* <GraphDataSelector data={[{id: 'test1', name:'Test 1'}, {id:'test2',name:'Test 2'}]}/> */}

          <GraphControlsUI graphControlSettings={graphControlSettings} settingsModifiedCallback={setGraphControlSettings}/>
        </div>
      </div>
    </div>
  )
}

export default GreeksGraphs;