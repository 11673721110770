//032021
// Object bank contains an object which will house key-accessable methods which will allow objects to be reconstructed from JSON.
//  each method should accept the object contents and return the desired object.

//import './MasterObjRef'

import {ModularContentComponent} from '../../_refactor030621/modularcontent/ModularContentComponent'
import {ContentGrid } from '../../_refactor030621/modularcontent/ContentGrid'
import {GridItemComponent} from '../../_refactor030621/modularcontent/GridItemComponent'
import {TestComponent1,TestComponent2,TestWidgetComponent} from '../../_refactor030621/_temp/testComponents'



let objArray = [ // place all components you want to be accesible in here.
    ModularContentComponent,
    ContentGrid,
    GridItemComponent,
    TestComponent1,
    TestComponent2,
    TestWidgetComponent
]



let objectBank = {}; // the components will be accesible via their names from here.
objArray.map(i=>objectBank[i.name]=i);



export {objectBank};
