//032021
// just creating a specific module for test/dummy components and content

//import {ReactChild,ReactComponentElement,ReactElement,ReactInstance,ReactNode, isValidElement} from 'react'
import {ModularContentObj, ContentObj, GridItem} from '../modularcontent/ModularContentObj'


// =========== TEST =================== 1
// function isClassComponent(component) {
//     return (
//         typeof component === 'function' && 
//         !!component.prototype.isReactComponent
//     )
// }

// function isFunctionComponent(component) {
//     return (
//         typeof component === 'function' && 
//         String(component).includes('return React.createElement')
//     )
// }

// function isReactComponent(component) {
//     return (
//         isClassComponent(component) || 
//         isFunctionComponent(component)
//     )
// }


export function TestComponent1({widgets}) {
    return ( //ReactChild,ReactComponentElement,ReactElement,ReactInstance,ReactNode
        <div>
            I am Test Component 1.
            Widget state: {widgets.widget1.state.count}
            Widget state: {widgets.widget2.state.count} <br/>
        </div>)
}

export function TestComponent2() {
    return (
        <div>
            I am Test Component 2.
        </div>)
}

export function TestWidgetComponent({text="default", state, setState}) {
    return (
        <div>
            <button onClick={()=>{setState({count:state.count+1})}}>Count {state.count}</button>
            Test Navbar Component <br/>
            {text}
        </div>)
}

export function getTestContentOptionsObj() {

    return {
        t1: new ContentObj({component:TestComponent1, title:"TestComp1", widgets:getTestWidgetGroup()}),
        t2: new ContentObj({component:TestComponent2, title:"TestComp2"})
    }
}

export function getTestWidgetGroup() {
    return {
        widget1: new ContentObj({component:TestWidgetComponent, title:"Test Nav Item",props:{text:"Not so default now"}, state:{count:0}}),
        widget2: new ContentObj({component:TestWidgetComponent, title:"Test Nav Item 2",props:{text:"Anddd It works"}, state:{count:0}}),
    }
}




//mock test of ModularContentComponent



export function getTestModularContent() {
    // constructs and returns a content obj
    let contentOptionsObj = getTestContentOptionsObj();
    return new ModularContentObj({name:"TestModularContent", contentOptionsObj})

}