/**103121
 * LiveOption
 * Subclass of OptionPosition class intended for using tda API data within the OptionPosition framework
 */


import * as bs from 'black-scholes'
import OptionPosition from './Option'
import getIV from '../util/impliedVolatility';


export default class LiveOptionPosition extends OptionPosition {
    constructor(tdaOptionData, tdaUnderlyingData, buySell="buy" ) {
        //console.log()
        let underlyingPrice = tdaUnderlyingData.bid;
        let callPut=tdaOptionData.putCall.toLowerCase()
        let strike=tdaOptionData.strikePrice;
        let dte = tdaOptionData.daysToExpiration;
        let riskFreeRate = 0.01 //todo: pull from 10 yr note
        let vol = getIV(underlyingPrice,strike,dte,riskFreeRate,callPut,tdaOptionData.mark, tdaOptionData.volatility/100) //old: tdaOptionData.volatility/100 //Todo: extract to OptionMethods
        super(underlyingPrice, buySell, callPut, strike, dte, vol, riskFreeRate )
        this.id=tdaOptionData.symbol;
        this.rawOptionData = tdaOptionData;
        this.rawUnderlyingData = tdaUnderlyingData;
    }

    getPremium = (args) => { //TODO: change to getValue()
        //todo: give user option to display mark, or bid for short and ask for long
        let livePremium = 0;
        if(args.useLive===true) {
            if(args.liveConfig!==undefined)
                if(args.liveConfig.useExecutionPrices) {
                    livePremium = (this.buySell=="buy") ? this.rawOptionData.ask : this.rawOptionData.bid;
                } else {livePremium = this.rawOptionData.mark}
        }
        return (args.useLive===true) ? livePremium : LiveOptionMethods.getPremium({...this, ...args})
    }

    getCreditDebit = (args) => {
        // returns net credit or debit amount based on option premium and the optionDirection
        return LiveOptionMethods.getCreditDebit({...this, ...args})
    }

    getIntrinsicValue = (args) => {
        // returns intrinsic value of an option given the params
        return LiveOptionMethods.getIntrinsicValue({...this, ...args})
    }

    getExtrinsicValue = (args) =>{
        return LiveOptionMethods.getExtrinsicValue({...this, ...args})
    }

    getPL = (args) => {
        // returns PL of an option
        if(!args) args={}
        args.creditDebit = this.getCreditDebit(); // use current premium.
        return LiveOptionMethods.getPL({...this, ...args})
    }

    getPLAtExpiration = (args) => {
        // returns PL of an option if underlying is at underlyingPrice at expiration
        if(!args) args={}
        args.creditDebit = this.getCreditDebit(); // use current premium.
        return LiveOptionMethods.getPLAtExpiration({...this, ...args})
    }

    getDelta = (args, priceDiff=1, adjustForPositionType=false) => {
        // returns Delta (change in option value over priceDiff change in underlying price)
        return (args===undefined || args.useLive === undefined || args.useLive) ? LiveOptionMethods.getDelta({...this, ...args}, priceDiff, adjustForPositionType) : this.tdaOptionData.delta;
    }

    getGamma = (args, priceDiff=1, adjustForPositionType=false) => {
        // returns Gamma (change in delta over priceDiff change in underlying price)
        return (args===undefined || args.useLive === undefined || args.useLive) ?  LiveOptionMethods.getGamma({...this, ...args}, priceDiff, adjustForPositionType) : this.tdaOptionData.gamma;
    }

    getVega = (args, volDiff=0.01, adjustForPositionType=false) => {
        // returns Vega (change in option value over volDiff change in volatility)
        return (args===undefined || args.useLive === undefined || args.useLive) ?  LiveOptionMethods.getVega({...this, ...args}, volDiff, adjustForPositionType) : this.tdaOptionData.vega
    }

    getTheta = (args, daysDiff=-1, adjustForPositionType=false) => {
        // returns Theta (change in option value over daysDiff change in time)
        return (args===undefined || args.useLive === undefined || args.useLive)  ? LiveOptionMethods.getTheta({...this, ...args}, daysDiff, adjustForPositionType) : this.tdaOptionData.theta
    }

    getRho = (args, rateDiff=0.01, adjustForPositionType=false) => {
        // returns Rho (change in option value over rateDiff change in riskFreeRate)
        return (args===undefined || args.useLive === undefined || args.useLive) ? LiveOptionMethods.getRho({...this, ...args}, rateDiff, adjustForPositionType) : this.tdaOptionData.rho
    }

    getGreeks = (args, adjustForPositionType=false) => { // todo: implement for LiveOption
        // returns json of greeks; adjustForPositionType will invert the greeks if this is a short option.
        // console.log("OptPos.getGreeks().adjust > ", adjustForPositionType)
        return LiveOptionMethods.getGreeks({...this, ...args}, adjustForPositionType);
    }

    getMaxLoss = (args) => {
        // returns maxLoss or null if infinite
        return LiveOptionMethods.getMaxLoss({...this, ...args});
    }

    getMaxProfit = (args) => {
        // returns max profit or undefined if infinite
        return LiveOptionMethods.getMaxProfit({...this, ...args});
    }

    getExpectedValue = (args) => {
        // returns expected value if loss is not infinite
        return LiveOptionMethods.getExpectedValue({...this, ...args});
    }

    getProbITM(args) {
        // returns probability of expiring ITM (calculates using delta);
        return LiveOptionMethods.getProbITM({...this, ...args});
    }

    getProbOTM(args) {
        // returns probability of expiring OTM (calculates using delta);
        return LiveOptionMethods.getProbOTM({...this, ...args});
    }

    getUnderlyingPriceAtBreakeven(args) {
        // returns the price that the underlying needs to be at expiration in order to break even
        if(!args) args={}
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : this.getCreditDebit();
        return LiveOptionMethods.getUnderlyingPriceAtBreakeven({...this, ...args});
    }

    getProbabilityOfProfit(args) {
        // returns POP -> probability of expiring with at least 0.01 profit.
        if(!args) args={}
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : this.getCreditDebit();
        return LiveOptionMethods.getProbabilityOfProfit({...this, ...args, creditDebit});
    }

}



export class LiveOptionMethods {

    static _getYearsFromDTE(daysToExpiration) {
        /** Returns  years as float from given daysToExpiration. e.g. 365 returns 1, */
        return daysToExpiration/365;
    }

    static getPremium(args) {
        const t = LiveOptionMethods._getYearsFromDTE(args.daysToExpiration);
        return bs.blackScholes(args.underlyingPrice, args.strike, t, args.volatility, args.riskFreeRate, args.callPut);
    }

    static getCreditDebit(args) {
        // returns net credit or debit amount based on option premium and the optionDirection
        let premium = LiveOptionMethods.getPremium(args);
        return (args.buySell=="buy") ? -premium : premium;
    }

    static getIntrinsicValue(args) {
        // returns intrinsic value of an option given the params
        var intrinsicValue = (args.callPut=="call") ? parseFloat(args.underlyingPrice) - args.strike : args.strike - args.underlyingPrice;
        if(intrinsicValue<0) intrinsicValue = 0; // ensure min zero.
        return intrinsicValue;
    }

    static getExtrinsicValue(args) {
        return LiveOptionMethods.getPremium(args) - LiveOptionMethods.getIntrinsicValue(args);
    }
    
    static getPL = (args) => {
        // returns PL of an option
        var optionValue = parseFloat(LiveOptionMethods.getPremium(args));
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : LiveOptionMethods.getCreditDebit(args);
        var pl = (args.buySell=="buy") ? optionValue + creditDebit: -optionValue + creditDebit;
        return pl;
    }
    
    static getPLAtExpiration(args) {
        // returns PL of an option if underlying is at underlyingPrice at expiration
        var intrinsicValue = (args.intrinsicValue!=null) ? parseFloat(args.intrinsicValue) : LiveOptionMethods.getIntrinsicValue(args);
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : LiveOptionMethods.getCreditDebit(args);
        var pl = (args.buySell=="buy") ? intrinsicValue + creditDebit: -intrinsicValue + creditDebit;
        return pl;
    }

    static getDelta(args, underlyingDiff=1, adjustForPositionType=false) {
        // returns delta (change in option value over change of priceDiff in underlying price)
        const curUnderlying = parseFloat(args.underlyingPrice);
        const newUnderlying = curUnderlying+parseFloat(underlyingDiff);
        const curOptionValue = LiveOptionMethods.getPremium(args);
        const newOptionValue = LiveOptionMethods.getPremium({...args, underlyingPrice: newUnderlying});
        const diffOptionValue = newOptionValue - curOptionValue;
        const diffUnderlying = newUnderlying - curUnderlying;
        let delta = (diffOptionValue/diffUnderlying)
        if(adjustForPositionType && args.buySell) delta *= ((args.buySell=="buy") ? 1 : -1);
        return delta;
    }

    static getGamma(args, underlyingDiff=1, adjustForPositionType=false) {
        // returns gamma (change in delta over priceDiff change in underlying price)
        const curUnderlying = parseFloat(args.underlyingPrice);
        const newUnderlying = curUnderlying+parseFloat(underlyingDiff);
        const curDelta = LiveOptionMethods.getDelta(args, underlyingDiff);
        const newDelta = LiveOptionMethods.getDelta({...args, underlyingPrice: newUnderlying});
        const diffDelta = newDelta - curDelta;
        const diffUnderlying = newUnderlying - curUnderlying;
        let gamma = (diffDelta/diffUnderlying);
        if(adjustForPositionType && args.buySell) gamma *= ((args.buySell=="buy") ? 1 : -1);
        return gamma;
    }

    static getVega(args, volDiff=0.01, adjustForPositionType=false) {
        // returns Vega (change in option value over volDiff change in volatility)
        const curVolatility = parseFloat(args.volatility);
        const newVolatility = curVolatility+parseFloat(volDiff);
        const curOptionValue = LiveOptionMethods.getPremium(args);
        const newOptionValue = LiveOptionMethods.getPremium({...args, volatility: newVolatility});
        const diffVolatility = (newVolatility - curVolatility)*100; //multipl by 100 to convert to pct before final calculation
        const diffOptionValue = newOptionValue - curOptionValue;
        let vega = (diffOptionValue/diffVolatility);
        if(adjustForPositionType && args.buySell) vega *= ((args.buySell=="buy") ? 1 : -1);
        return vega;
    }

    static getTheta(args, daysDiff=-1, adjustForPositionType=false) {
        // returns Theta (change in option value over daysDiff change in time)
        const curDaysToExpiration = parseFloat(args.daysToExpiration);
        const newDaysToExpiration = curDaysToExpiration+parseFloat(daysDiff);
        const curOptionValue = LiveOptionMethods.getPremium(args);
        const newOptionValue = LiveOptionMethods.getPremium({...args, daysToExpiration: newDaysToExpiration});
        const diffOptionValue = newOptionValue - curOptionValue;
        const diffDaysToExpiration = newDaysToExpiration - curDaysToExpiration;
        let theta = (diffOptionValue/diffDaysToExpiration);

        // console.log("getTheta().curDte", curDaysToExpiration)
        // console.log("getTheta().newDte", newDaysToExpiration)
        // console.log("getTheta().diffDte", diffDaysToExpiration)
        // console.log("getTheta().curVal", curOptionValue)
        // console.log("getTheta().newVal", newOptionValue)
        // console.log("getTheta().diffVal", diffOptionValue)

        if(diffDaysToExpiration<0) theta *=-1; // TODO: above calculation yield positive theta. Cannot figure out how best to calculate true negative theta atm. adding this here as a placeholder.

        if(adjustForPositionType && args.buySell) theta *= ((args.buySell=="buy") ? 1 : -1);
        return theta;
    }

    static getRho(args, rateDiff=0.01, adjustForPositionType=false) {
        // returns Rho (change in option value over rateDiff change in riskFreeRate)
        const curRiskFreeRate = parseFloat(args.riskFreeRate);
        const newRiskFreeRate = curRiskFreeRate+parseFloat(rateDiff);
        const curOptionValue = LiveOptionMethods.getPremium(args);
        const newOptionValue = LiveOptionMethods.getPremium({...args, riskFreeRate: newRiskFreeRate});
        const diffOptionValue = newOptionValue - curOptionValue;
        const diffRiskFreeRate = (newRiskFreeRate - curRiskFreeRate) *100; // multiply by 100 to convert pct before final calc
        let rho = (diffOptionValue/diffRiskFreeRate);
        if(adjustForPositionType && args.buySell) rho *= ((args.buySell=="buy") ? 1 : -1);
        return rho;
    }


    static getGreeks(args, adjustForPositionType=false) {
        // returns json of greeks
        let greeks = {
        "delta": LiveOptionMethods.getDelta(args, undefined, adjustForPositionType), 
        "gamma": LiveOptionMethods.getGamma(args, undefined, adjustForPositionType), 
        "vega": LiveOptionMethods.getVega(args, undefined, adjustForPositionType), 
        "theta": LiveOptionMethods.getTheta(args, undefined, adjustForPositionType), 
        "rho": LiveOptionMethods.getRho(args, undefined, adjustForPositionType) 
        };          //TODO: the way you are passing these params is messy. Struggled for a bit not being able to assign values via param names.. look into more robust/safe/readable method for sending the adjustForPositionType param without needing to have it in the right position
        return greeks;
    }


    static getMaxLoss(args) {
        // returns maxLoss or undefined if infinite
        
        // if buy, loss=premium * qty
        // if sell call, loss=undefined
        // if sell put, loss=(distance to 0 from strike - credit) * qty
        if(args.buySell=="buy") return LiveOptionMethods.getPremium(args);
        if(args.callPut=="put") return parseFloat(args.strike) - LiveOptionMethods.getPremium(args);
        return undefined;
    }

    static getMaxProfit(args) {
        // returns max profit or undefined if infinite
        // if buy call, profit = undefined
        // if buy put, profit = distance to 0 + debit
        // if sell, profit = premium

        if(args.buySell!="buy") return LiveOptionMethods.getPremium(args);
        if(args.callPut=="put") return parseFloat(args.strike) + LiveOptionMethods.getPremium(args);
        return undefined;
    }

    static getExpectedValue(args) {
        // returns expected value if loss is not infinite
            // uses delta as probability
        //TODO: this is currently just going to be calculating EV based on prob ITM... should make a variant that calculates EV based on prop Profit (depends on premium)

        // EV = P(gain)*gain + P(loss)*loss
        // P(gain) = probITM if buy, or probOTM if sell
        //  probITM = delta; probOTM = 1-delta
        // P(loss) = probOMT if buy, or probITM if  sell
        
        const delta = Math.abs(LiveOptionMethods.getDelta(args));
        const maxProfit = LiveOptionMethods.getMaxProfit(args);
        const maxLoss = LiveOptionMethods.getMaxLoss(args);
        if(maxProfit==undefined || maxLoss==undefined) return undefined;

        const probWin = (args.buySell=="buy") ? LiveOptionMethods.getProbITM(args) : LiveOptionMethods.getProbOTM(args);
        const probLoss = 1-probWin;
        const ev = probWin*maxProfit + probLoss*maxLoss;
        return ev;        
    }

    static getProbITM(args) {
        // returns probability of expiring ITM (calculates using delta);
        return Math.abs(LiveOptionMethods.getDelta(args));
    }

    static getProbOTM(args) {
        // returns probability of expiring OTM (calculates using delta);
        return 1-Math.abs(LiveOptionMethods.getDelta(args));
    }

    static getUnderlyingPriceAtBreakeven(args) {
        // returns the price that the underlying needs to be at expiration in order to break even
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : LiveOptionMethods.getCreditDebit(args);
        return (args.callPut=="call") ? parseFloat(args.strike) + Math.abs(creditDebit) : parseFloat(args.strike) - Math.abs(creditDebit);

    }

    static getProbabilityOfProfit(args) { //TODO: need to verify
        // returns POP -> probability of expiring with at least 0.01 profit.
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : LiveOptionMethods.getCreditDebit(args);
        const breakeven = LiveOptionMethods.getUnderlyingPriceAtBreakeven({...args, creditDebit});
        const profitPrice = breakeven + (args.callPut=="call" ? 0.01 : -0.01);

        const propITM = LiveOptionMethods.getProbITM({...args, strike: profitPrice});
        const probOTM = LiveOptionMethods.getProbOTM({...args, strike: profitPrice})

        // console.log("breakeven", breakeven);
        // console.log("profitPrice", profitPrice);
        // console.log("probITM At profitPrice() > ", propITM);
        // console.log("probOTM At profitPrice() > ", probOTM);
        return args.buySell=="buy" ? propITM : probOTM;
    }

}