/**
 * 040922
 * 
 */

 import '../App.css';
 import '../../node_modules/react-grid-layout/css/styles.css'
 import '../../node_modules/react-resizable/css/styles.css'
 import { BrowserRouter, Route, Routes } from 'react-router-dom';
 import {useState, useRef} from 'react'
 import NavBar from './components/content/NavBar'
 import ProfitLossStudy from './components/content/ProfitLossStudy'
 import ValueStudy from './components/content/ValueStudy'
 import DynamicStudy from './components/content/DynamicStudy'
 import PyTest from './components/content/pythonTests'
 import APIDataTest from './components/content/APIDataTest'
 import NodeServerTestComponent from './components/content/nodeServerTest'
//  import {TradingViewWidgetTest, TradingViewLightWeightTest, TradingViewFullTest} from './components/content/TradingViewTest'
 import {v4 as uuid} from 'uuid/dist/v4'
 import OptionChain from './components/content/OptionChain.jsx';
 import { RefactorTestPage } from './_refactor030621/RefactorTestPage';
 import { RefactorTestPage2 } from './_refactor030621/RefactorTestPage2';
 import {FredApiTestFC} from './components/content/FredApiTest';
 import LiveDynamicStudy from './components/content/LiveDynamicStudy';
 import ImpliedVolatilityTest from './components/content/ImpliedVolatilityTest'
 import { Futures } from './components/content/Futures';



 function Modal(props) {
   return (
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{props.title}</h5>
            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
          </div>
          <div class="modal-body">
            {props.body}
          </div>
          <div class="modal-footer">
            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
            <button type="button" class="btn btn-primary">{props.buttonText}</button>
          </div>
        </div>
      </div>
    </div>
 )
 }
 
 
 
 export function OptionsApp() {
   const content = {
     "Theoretical Position Analysis": <DynamicStudy/>, 
     "Theoretical Value Study": <ValueStudy/>, 
     "|":null, //divider
     "Live Position Analysis": <LiveDynamicStudy/>,
     "Live Data Dashboard": <APIDataTest/>,
     "|":null, //divider
     "Futures": <Futures/>
     // "PL Study": <ProfitLossStudy/>, 
     
     // "Python Test": <PyTest/>, 
     // "Node Server Test": <NodeServerTestComponent/>,
     // "Tradingview Test": <TradingViewFullTest/>,
     
     // "Option Chain Test": <OptionChain/>,
     
     // "Refactor Workspace": <RefactorTestPage/>,
     // "Refactor Workspace 2": <RefactorTestPage2/>,
     // "FredAPI Test": <FredApiTestFC/>,
    //  "IV Test": <ImpliedVolatilityTest/>
   }
   const [currentContent, setCurrentContent] =  useState("Theoretical Position Analysis"); //useState(content[Object.keys(content)[0]]);
 
   // console.log("currentContent> ", currentContent);
   // console.log("content", Object.keys(content))
 
 
   return (
     <div className="container-fluid">
       {/* <Modal body='This site is currently under construction and may contain bugs. \n None of the information on this site is to be considered financial advice.' title='Terms of Use' buttonText='I understand.'/> */}
       <NavBar contentArray={Object.keys(content)} currentContent={currentContent} setCurrentContent={setCurrentContent}/>  
       <h3>{currentContent}</h3>
       {content[currentContent]}
     </div>
   );
 }