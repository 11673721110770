//042521
// FRED API
// testing this js api wrapper: https://github.com/Rleahy22/fredApi

// apikey: e751beaf660729de4e7c1ae783a3a999



import Fred from 'fred-api';

const apikey = 'e751beaf660729de4e7c1ae783a3a999';

export const fred = new Fred(apikey);

