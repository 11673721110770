// 021321
// Component that shows a graph of Extrinsic Value across strike prices


import { useState } from 'react';
import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ComposedChart } from 'recharts';
import GraphControlsUI from './GraphControlsUI'


function getExtrinsicValueData(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10) {
  // takes array of objects that include {strike, netPremium, optionType, optionDirection}
  if(optionsData.length==0) return [];
  const data = []
  const lowestStrike = parseFloat(optionsData.reduce((min, o) => o.underlyingPrice < min ? o.underlyingPrice : min, optionsData[0].underlyingPrice));
  const highestStrike = parseFloat(optionsData.reduce((max, o) => o.underlyingPrice > max ? o.underlyingPrice : max, optionsData[0].underlyingPrice));

  for(var i=lowestStrike-Math.abs(relLowerBound); i<highestStrike+Math.abs(relUpperBound); i+=dataIncrements) {

      var cumExtrinsic = 0;
      for(let opt of optionsData) {
        cumExtrinsic += opt.getExtrinsicValue({strike: i});
      }
      var extrinsicAbsoluteRng = [0, cumExtrinsic];
      data.push({strike: i, extrinsicAbsoluteRng});
  }
  return data;
}

function ExtrinsicValueGraph({optionPositions}) {

  const [graphControlSettings,setGraphControlSettings] =useState({bounds: 15, granularity: 0.5});

  const data = getExtrinsicValueData(optionPositions, graphControlSettings.granularity, graphControlSettings.bounds, graphControlSettings.bounds);

  return (
    <div className="row">
      {/*JSON.stringify(optionPositions)*/}
      <div className="section col-md-10">
        <h3 className="section-title">Extrinsic Value:</h3>
        <div className="section-content">

          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="strike" name="Strike" label="Strike"/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Area type="monotone" dataKey="extrinsicAbsoluteRng" name="Extrinsic Value at Strike" stroke="#107dac" fill="#107dac" fillOpacity={0.25}/>
              {/* <Line type="monotone" dataKey="cumExtrinsic" name="ExtrinsicValue" stroke="#107dac" strokeWidth={2} dot={false}/> */}
            </ComposedChart>
          </ResponsiveContainer>

          <GraphControlsUI graphControlSettings={graphControlSettings} settingsModifiedCallback={setGraphControlSettings}/>
        </div>
      </div>
    </div>
  )
}

export default ExtrinsicValueGraph;