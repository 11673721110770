//022721
// workspace for developing the td ameritrade api wrapper.

// TODO: use URLsearch params to build queries


function getApiKey() {
    return "R8ASNE4CDQX8FKL674V2IMPEKBGTOGR0";
}

function getApiKeyParam() {
    return "apikey="+getApiKey();
}

export function getOptionChainData_raw(symbol, strikeCount="5") {
    //returns {callExpDateMap: {ExpDate: {strike: [Option]}}, putExpDateMap: ...}
    const otherParams = "&symbol="+symbol+"&contractType=ALL&strikeCount="+strikeCount+"&includeQuotes=TRUE";
    const urlStr = "https://api.tdameritrade.com/v1/marketdata/chains?"+getApiKeyParam()+otherParams;
    //console.log(urlStr)
    return fetch(urlStr)         
        .then(res=>res.json())
        .catch(err=>console.log("err > ", err)); //console.log("err > ", err)
}

export function getOptionChainData(symbol, strikeCount="5") {
    // returns option chain data formatted for easier use: {exp: {strike: {call: , put: }}}
    if(symbol==null) return;
    return getOptionChainData_raw(symbol, strikeCount).then((data)=>{
        let chainData = {};
        for(let expDate in data.callExpDateMap) {
            
           const f_expDate = expDate.split(":")[0] //TDA returns expdate as '2022-04-06:#', so we're stripping the colon and number at the end.
            // console.log('getOptionChainData()>', {expDate,f_expDate,data})
            if(!chainData.hasOwnProperty(f_expDate)) chainData[f_expDate]= {};
            
                for(let s in data.callExpDateMap[expDate]) {
                    try {
                        if(!chainData[f_expDate].hasOwnProperty(s)) chainData[f_expDate][s]= {};
                        chainData[f_expDate][s]["call"]=data.callExpDateMap[expDate][s][0];
                        chainData[f_expDate][s]["put"]=data.putExpDateMap[expDate][s][0];
                    } catch {
                    // console.log('getOptionChainData() 2 >', {f_expDate, chainData, s, 'call':data.callExpDateMap[expDate][s],'put':data.putExpDateMap[expDate][s]})
            }
                }
            }

        // console.log('getOptionChainData()>', {expDate,f_expDate,data})

        // if(!chainData.hasOwnProperty(expDate)) chainData[expDate]= {};
        
        //     for(let s in data.callExpDateMap[expDate]) {
        //         try {
        //             if(!chainData[expDate].hasOwnProperty(s)) chainData[expDate][s]= {};
        //             chainData[expDate][s]["call"]=data.callExpDateMap[expDate][s][0];
        //             chainData[expDate][s]["put"]=data.putExpDateMap[expDate][s][0];
        //         } catch {
        //         console.log('getOptionChainData() 2 >', {expDate, chainData, s, 'call':data.callExpDateMap[expDate][s],'put':data.putExpDateMap[expDate][s]})
        // }
        //     }
           
                
        // }

        // for(let k in data.putExpDateMap) {
        //     if(!chainData.hasOwnProperty(k)) chainData[k]= {};
        //     for(let s in data.putExpDateMap[k]) {
        //         if(!chainData[k].hasOwnProperty(s)) chainData[k][s]= {};
        //         chainData[k][s]["put"]=data.putExpDateMap[k][s][0];
        //     }
        // }


        let formattedChainData = {chainData,  underlying: data.underlying}

        return formattedChainData;
    })
}