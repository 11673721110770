// 021721
// MultiOptionPosition Class
// Represents a group of option and can perform calculations on the group.
//  - Taking most functionality from existing OptionPositiongList component.

import OptionPosition from './Option'
import StatefulList from './StatefulList'

// with stateful list
// class MultiOptionPosition extends StatefulList {

//   constructor(listId, stateRef, setStateRef) {
//       super(listId, stateRef, setStateRef);
//       this.callbacks = {...this.callbacks, convertFromJSON: this.convertFromJSON, onReset: this.onReset, getNewItem: this.getNewPosition}

//   }

//   convertFromJSON = (jsonStr) => {
//       let loadedPositions = [];
//       for(let i of jsonStr) {
//         loadedPositions.push(new OptionPosition(i.underlyingPrice,i.buySell,i.callPut,i.strike,i.daysToExpiration,i.volatility,i.riskFreeRate))
//       }
//       return loadedPositions;
//     }

//   onReset = () => {
//       this.addNewItem(new OptionPosition());
//   }

//   getNewPosition = () => {
//     console.debug("getNewPosition()")
//     return new OptionPosition();
//   }

//   getNetGreeks = (adjustForPositionType=true) => {
//     // returns json of net greeks from all positions
//     let netGreeks = {"delta": 0, "gamma": 0, "vega": 0, "theta": 0, "rho": 0};
//     for(let pos of this.list) {
//       let posGreeks = pos.getGreeks(undefined, adjustForPositionType); //TODO: can't seem to use a named param here to assign a value to adjustForPositionType param
//       for(let g in posGreeks) {netGreeks[g]+=posGreeks[g]}
//     }
//     return netGreeks;
//   }

//   getNetCreditDebitAmt = () => {
//     // returns net creditDebit from all positions
//     // return positions.reduce((acc, curr) => acc+= curr.getCreditDebit()) //TODO: use reduce here instead of loop below
//     let net = 0.0;
//     for(let i of this.list) {
//       net+=parseFloat(i.getCreditDebit());
//     }
//     return net.toFixed(2);
//   }

//   getMaxLoss() {
//     // returns net Max loss for entire position

//     // maybe just use cumPL across range from all strikes
//     // then to determine whether either side is undefined, test the slope of pl outside the range/
//     //  if it's ascending or descending beyond the highest strike + premium, then it's undefined (if facing toward stock rising)
//     //    ... or if it's facing toward zero, going up is undefined, and going down is limited to loss at zero.
    

//   }

//   getMaxProfit() {
//     // returns net Max profit for entire position

//   }

// }


// pre-statefullist
class MultiOptionPosition {

    constructor(listId, positions=[], positionModifiedCallback=null) {
        this.listId = listId; // used for saving/loading.
        this.positions = positions;
        this.positionModifiedCallback = positionModifiedCallback; //TODO: 021821 does not work w/ state atm..causes renderloop
        //this.loadFromLocal();
    }

    onPositionsModified = () => {
        // contains any code that needs to be run when positions are modified.
        //this.saveToLocal();
        //console.log("MOP.onPositionsModified()")
        if(this.positionModifiedCallback!=null) {
            this.positionModifiedCallback(this.positions);
            //console.log("MOP.onPositionsModified()> callBack made | pos: ", this.positions)
        }
    }

    setPositions = (newPositions) => {
        this.positions = newPositions;
        this.onPositionsModified();
    }

    saveToLocal = (listId=null) => {
        if(listId==null) listId= this.listId;
        localStorage.setItem(listId,JSON.stringify(this.positions))
    }

    loadFromLocal = (listId=null) => {
        if(listId==null) listId= this.listId;
        let loadedJSON = JSON.parse(localStorage.getItem(this.listId))
        if(loadedJSON!==null) {
            let loadedPositions = [];
            for(let i of loadedJSON) {
            loadedPositions.push(new OptionPosition(i.underlyingPrice,i.buySell,i.callPut,i.strike,i.daysToExpiration,i.volatility,i.riskFreeRate))
            }
        this.setPositions(loadedPositions);
      }
    }

    resetList = () => {
        localStorage.removeItem(this.listId);
        this.setPositions([new OptionPosition()]);
    }

    addNewPosition = () => {
        const newPosition = new OptionPosition();
        this.positions.push(newPosition);
        this.updatePosition(newPosition); //not sure why, but this is the only way I found that doesn't suffer from the late render issue. (e.g. tried pushing directly, then calling set positions, or onPositionModified, etc.)
    }

    removePositionById = (id) => {
      const newPositions = this.positions.filter((pos)=>{
        return pos.id !== id;
      })
      this.setPositions(newPositions);
    }

    updatePosition = (optionPositionObj) => {
        const newPositions = this.positions.map((pos) => {
            if(pos.id===optionPositionObj.id) {
            return optionPositionObj;
            }
            return pos;
        })
        this.setPositions(newPositions);
    }

    getNetGreeks = (adjustForPositionType=true) => {
      // returns json of net greeks from all positions
      let netGreeks = {"delta": 0, "gamma": 0, "vega": 0, "theta": 0, "rho": 0};
      for(let pos of this.positions) {
        let posGreeks = pos.getGreeks(undefined, adjustForPositionType); //TODO: can't seem to use a named param here to assign a value to adjustForPositionType param
        for(let g in posGreeks) {netGreeks[g]+=posGreeks[g]}
      }
      return netGreeks;
    }

    getNetCreditDebitAmt = () => {
      // returns net creditDebit from all positions
      // return positions.reduce((acc, curr) => acc+= curr.getCreditDebit()) //TODO: use reduce here instead of loop below
      let net = 0.0;
      for(let i of this.positions) {
        net+=parseFloat(i.getCreditDebit());
      }
      return net.toFixed(2);
    }

    getMaxLoss() {
      // returns net Max loss for entire position

      // maybe just use cumPL across range from all strikes
      // then to determine whether either side is undefined, test the slope of pl outside the range/
      //  if it's ascending or descending beyond the highest strike + premium, then it's undefined (if facing toward stock rising)
      //    ... or if it's facing toward zero, going up is undefined, and going down is limited to loss at zero.
      

    }

    getMaxProfit() {
      // returns net Max profit for entire position

    }

    getNPositions() {
      // returns number of positions
      return this.positions.length;
    }

}

export default MultiOptionPosition;