//032721
// moving the working graphOverhaul code in here from RefactorTestPage; will separate it later;

import {useState, useEffect} from 'react'

import { ResponsiveContainer, Area, ComposedChart,ReferenceLine, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {getDummyData} from '../../util/dataUtil'
import {ContentObj} from '../modularcontent/ModularContentObj'
import {getGraphData} from './tempDataUtil'

// ======================================
// Workspace below
// ======================================

/*
    Brainstorm notes:
        
        - Graphs should be dumb. no matter what graphtype (scatter, line, 3d, etc),
            ... you should be able to provide standardized props which will allow it to generate the relevant visualization.

        approaches:
            - can structure individual graph types as contentobjs, then build a ModularGraphComponent as a
                super class of ModularContentComponent.. it will contain all graph types and supply them as contentOptionsObj to a ModularContentComponent in render.

            - build ContentObjs using GraphComponent, and individually set each type as the init state.

        design decision: do we declare the dataComponent func in the GraphObj, or should it be in the GraphComponents?

        design decision: kind of thinking data funcs don't need to operate at individual datapoints.. might be better to perform each operation on entire series and return that.
            - going to rewrite existing dataUtil.getDynamicData2d to use this approach and commpare.
                ... if it works well, probably best to use this for DataItemObj.
            - dataItem.id should reference a property in the datasource's object.

            - approaches:
                - can structure all dataItem methods to be executed per-index over another axis (i.e. expect an input to operate on)
                    - maybe can subclass this or use some indication when you just want to use an existing series in the data, so that you don't waste time
                        ... iterating through needlessly.
                - maybe can have DataSource be responsible for containing the function to extract a series of data from the source, then the
                    ... GraphObj can be responsible for utilizing that series in a way appropriate for the graph?
                - can structure all dataItem methods to return a series using an interfaced method signature.
                    - e.g. for sim options, you provide optionspositions as data, and ...  
                        ... actually, just try to build something out like this. it will make it easier to understand.
                            use a secondary axisOptions with some converted methods... and an associated getData func.
                - some sort of universal data interface that allows you to get y at x?
                - dataSource carries the methods to interface with the data. there is a method for 2d and 3d (and whatever else)
                    ... the graph utilizes the appropriate method.
                    - DataItem methods take standardized input to act on per-index. 
        
        DataItems should reference accessible or calculatable properties of DataSource
        dataItems[targetDataItem](source, args={[testDataItem]: value})
            -this should return the value of targetDataItem where testDataItem==value in DataSource


                -  we calculate the series we want to pass first, then request the target variable for
                    ... everyvalue in the series.
                    -e.g. if we want y at x, we calculate the x series first, then pass: 
                        dataItems[targetDataItem](source, args={[testDataItem]: [dataSeries]})
                            - this should return a series of targetDataItem for every testDataItem value



        - 032721 Process brainstorm:
            - Construct available graphTypes (composite, scatter, scatter 3d, etc.) by hardcoding their GraphTypeObjs and using that to create a GraphContentObj.
                - The GraphTypeObj is responsible for containing the data and instructions needed to construct a particular type of graph from sub components.
            - A user will be able to choose from the available GraphContentObjs to display.
            - The GraphBaseComponent is responsible for actually constructing the graph using the components from GraphObj and populating the graphData and other state variables using GraphStateObj.
            
            - All graphs should be able to display a standardized "dataseries" format, [datapoint1, datapoint2, etc.] where each "datapoint" is an obj containing labels and values: {label1: value, label2: value}
                - the axis to which each label is assigned will be described in the DataConfigObj.
            
            - To Create a DataSource:
                - Create a datasource interface object
                - hardcode the available dataItems for the datasource.
                - Use the datasource interface object and the dataItems to construct a DataSourceObj.

            - To display different types of data on the graphs, the user will choose from available DataItems for the current DataSource inside the DataConfigComponent.
                - The dataConfigComponent displayed will be different depending on the graphType. This means a 3d graph will allow for 3 axes, whereas a 2d graph will only permit two. //todo: this can be the same component dynamically rendered:
                - the options selected in the dataConfigComponent will update graphState.dataConfig with the new selections.
                - graphState.dataConfig.currentData will be updated with a new dataseries by passing the datasource and the new properties into the getDataSeries() func
                       -  //todo: might consolidate graphState.currentData and .dataSource into sub property.. possible dataConfig? but seems messy. //update: trying it in dataConfig for now.
                       -  //todo: kind of want to make getDataSeries() a method of one of these objects, but nowhere makes great sense atm. 
                            - possibly will just have inside basecomponent?
                            - It seems like a nice solution to add it as method to DataSource, allowing it to just take the desired properties, then reference itself and return the new dataseries.
                                ... but this would not be a good solution if you're keeping the getDataSeries_multiSource() variant.
                                    - The only reason we need this might atm was because the current method takes an array of OptionPositions. However, this can be fixed by making the data interface a MultiOptionPosition class.
                                        ... then, all datasource interfaces should be single objects.  


    
*/



// ============== COMPONENTS =================================

function GraphDataComponent_recharts (graphDataItems) {
    // takes graphDataItems and returns jsx 

    let componentRef = {
        'line': Line,
        'area': Area,
        'bar': Bar
    }

    return graphDataItems.map((i)=>{
        let Component = componentRef[i.plotType];
        let props = { //converting graphDataItem props into the propnames required by rechart.
            key:i.dataKey,
            type:"monotone",
            name: i.title,
            dataKey: i.dataKey,
            stroke: i.color,
            strokeWidth: i.size,
            fill: i.fillColor,
            ...i.otherAttributes
        }
        return <Component {...props} />
    });
}

function GraphComponent_recharts ({graphState, graphDataComponents}) {
    // return jsx for test graph component 
    // todo: break this down into a general factory obj to dynamically construct and return the complete jsx to display a rechart composed chart.
    //  -i.e. have a function that creates axis components (if z exists, then add z, etc.) and the grid and legend. 
    //          ... then, these can all be built using user-variables stored in graphstate.
    //console.log("GraphComponent_recharts > graphDataComponents", graphDataComponents)
    console.log("GraphComponent_recharts graphState", graphState)
    let data = graphState.dataConfig.currentDataSeries
    let xDataItem = graphState.dataConfig.activeDataItems.x;
    //let yDataComponents = GraphDataComponent_recharts(graphState.dataConfig.activeDataItems.y); //todo: not sure if it makes more sense to have this in the GraphComponent func, or in the GraphTypeObj as originally intended. trying it here for now.
    return (
        <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 5, right: 25, bottom: 5, left: -5 }}>
            <Tooltip formatter={fixedValueTooltipFormatter} labelFormatter={(value)=>xDataItem.title+": "+value}/>
            <XAxis dataKey={xDataItem.key} name={xDataItem.title}/>
            <YAxis />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Legend verticalAlign="bottom" height={0} margin={{top:0}}/>
            {graphDataComponents}
            </ComposedChart>
        </ResponsiveContainer>
    )
}


export function GraphDataConfigComponent() {
    return (<div>
        GraphDataConfigComponent
    </div>)
}

export function GraphConfigComponent() {
    return (<div>
        GraphDataSelctionComponent
    </div>)
}

export function GraphBaseComponent({id, graphTypeObj, state}) {
    console.log("GraphBaseComponent > graphTypeObj", graphTypeObj)
    console.log("GraphBaseComponent > state", state)
    //todo: useMemo to only update data when graphState has changed.

    let graphState = state.graphState;

    const [currentDataSeries, setCurrentDataSeries] = useState(); //todo: if you keep this here, remove from graphState.dataConfig

    useEffect(()=>refreshDataSeries(),[graphState])

    function refreshDataSeries() {
        let d = graphState.dataConfig.graphDataFunc({graphState});
        setCurrentDataSeries(d) //todo: this does not seem to set correctly, though maybe just was trying to console.log async.
        graphState.dataConfig.currentDataSeries = d; //todo: just here for testing; remove.
    }

    let graphDataComponents = graphTypeObj.dataComponent(graphState.dataConfig.graphDataItems)
    if(graphTypeObj==null) return (<div>This is an uninitialized Graph Component</div>)
    let graphComponent = graphTypeObj.graphComponent!=null ?  graphTypeObj.graphComponent({graphState, graphDataComponents}): <></>;
    let DataConfigComponent = graphTypeObj.dataConfigComponent!=null ?  graphTypeObj.dataConfigComponent(): <></>;
    let GraphConfigComponent = graphTypeObj.graphConfigComponent!=null ?  graphTypeObj.graphConfigComponent(): <></>;;

    return (<div>
        {graphComponent}
    </div>)
}

// ============== OBJECTS/FACTORIES =================================

function GraphConfigObj({config}) {
    // Constructor that describes structure of GraphConfig
    // todo: not even sure what this is supposed to represent at this point. Will come back to it.
    this.config = config;
}

function GraphDataItem({title, dataKey, plotType="line", size=1, color="#107dac", fillColor="#107dac",opacity=1,...otherAttributes}) {
    // Constructor for object that contains the data to describe how data should look on a graph
    // ... the actual jsx component will be constructed by converting this data into the protocol required by the graph api.
    this.title=title; //displayName
    this.dataKey=dataKey; // what key is this item associated with in the dataSeries
    this.plotType=plotType; // "line", "area", etc.
    this.size=size;
    this.color=color;
    this.fillColor=fillColor;
    this.opacity=opacity;
    this.otherAttributes=otherAttributes;
}



function DataConfigObj({dataSource, currentDataSeries, activeDataItems, graphDataFunc, graphDataItems}) {
    // Constructor that describes structure of DataConfig
    // DataConfig is part of GraphState and contains data about selected Data, and their attributes

    this.dataSource = dataSource;
    this.currentDataSeries = currentDataSeries; //todo: consider keeping this in GraphBaseComponent
    this.activeDataItems=activeDataItems; //{x: dataItem, y: [dataItems], z: [dataItems]}
    this.graphDataFunc = graphDataFunc; //todo: is this the right name? maybe this should be an optiohnal dataformatter or interface method? We don't need it if our dataSource already provides a dataseries.
    this.settings = {bounds: 10, granularity:20} //temporary while testing.
    this.graphDataItems = graphDataItems; //todo: revisit where/how this is stored.
}

// function operation

function DataItemObj({title, key, calc, valueType="continuous", reducer}) {
    // Constructor that describes structure of DataItem; 
    // A DataItem represents a method to extract data from a DataSource.
    // e.g. ...
    //todo: design decision: kind of thinking data funcs don't need to operate at individual datapoints.. might be better to perform each operation on entire series and return that.
    //todo: perhaps DataItemObjs don't need to be properties of datasource, but can also be a method which could accept the datasource or an entire series.
        // ...these can be included as a type param and/or subclassed and declared as needed; their behavior can be handled by the getGraphData() func.
        // ...a DataItemObj that operates on the entire series could be known as a SeriesOperation and can be run once on the entire dataseries after generation, then added as an individual property.
    this.title = title;
    this.key = key;
    this.valueType = valueType; // valueType = continuous | discrete //todo: this should be an enum
    this.reducer = (reducer==null) ? (data)=>data.reduce((a,b)=>a+b) : reducer; // reducer tells this how to combine multiple inputs into a single datapoint output at each index
    
}

function DataSourceObj({source, dataItemsObj}) {
    // Constructor that describes structure of DataSource
    this._source = source; //todo: might not need this if we can just set it directly in this.getData; but be sure to think about json revival
    this.dataItems = dataItemsObj; // set of dataItems compatible with this source
    this.getData=()=>(typeof(this._source)==='function') ? this._source() : this._source;
}

function GraphStateObj({id,dataConfig,graphConfig}) {
    // Constructor that describes structure of GraphState
    this.id=id;
    this.dataConfig=dataConfig;
    this.graphConfig=graphConfig;
}


function GraphTypeObj({ 
    // obj constructor to contain data needed to construct graph
    title, // display name
    graphComponent, // component that returns JSX for graph body. should take data items
    dataComponent, // component that returns JSX for graph data; should take GraphDataItems
    dataConfigComponent= GraphDataConfigComponent, // component that displays UI for user to configure graph data (choose data, and visual)
    graphConfigComponent= GraphConfigComponent // component that displays UI for user to configure options related to the graph itself. //todo: intially thought this would be data granularity, zoom, etc. but those may fit in elsewhere, e.g. the dataConfig, or as part of the graphBaseComponent
    }) {

        this.title = title;
        this.graphComponent = graphComponent;
        this.dataComponent = dataComponent;
        this.dataConfigComponent = dataConfigComponent;
        this.graphConfigComponent = graphConfigComponent;
}


function getGraphContentObj({id, title, graphTypeObj, initGraphState={}}) { 
    // ContentObj factory for Graphs using GraphBaseComponent
    let component = GraphBaseComponent;
    title = (title==null) ? graphTypeObj.title : title;
    let props = {graphTypeObj}
    let state = {graphState: initGraphState}
    return new ContentObj({id,component, title, props, state})
}



// ============== GRAPH TEST CONSTRUCTION AREA =================================



function fixedValueTooltipFormatter(value, name, props) { //Util for TestGraphComponent()
    // formats tooltip data to fixed value
    let nDecimals = 2;
    if(value>1 || value<-1) {nDecimals=2}
    else nDecimals=6;
    value = parseFloat(value).toFixed(nDecimals)
    return [value, name];
  }




export function getTestGraphContentObj() {
    // returns content obj for testGraph
    let testGraph_graphType = new GraphTypeObj({
        title:"TestGraph1", 
        graphComponent: GraphComponent_recharts, 
        dataComponent: GraphDataComponent_recharts,
        dataConfigComponent: GraphDataConfigComponent,
        graphConfigComponent: GraphConfigComponent
    })
    
    
    let testGraph_dataItems = { // test items meant to be used with getDummyData()
        "testDataItem1": new DataItemObj({title:"Test DataItem 1", key:"x"}), 
        "testDataItem2": new DataItemObj({title:"Test DataItem 2", key:"y"})
    }
    
    let testGraph_dataSource = new DataSourceObj({source: getDummyData(20), dataItemsObj: testGraph_dataItems})
    
    let testGraph_graphDataItems = [
        new GraphDataItem({title:"Test GraphDataItem", dataKey:"y"})
    ]

    let testGraph_dataConfig = new DataConfigObj({
        dataSource: testGraph_dataSource, 
        activeDataItems: {x:testGraph_dataItems['testDataItem1'],y: testGraph_dataItems['testDataItem2']},
        graphDataFunc: ({graphState})=>{return graphState.dataConfig.dataSource.getData()}, //getGraphData -- since getDummyData is already formatted as dataseries, we don't need to do any more.
        graphDataItems: testGraph_graphDataItems
    })
    
    let testGraph_graphConfig = new GraphConfigObj({});
    
    let testGraph_initGraphState = new GraphStateObj({dataConfig:testGraph_dataConfig, graphConfig: testGraph_graphConfig})
    
    let testGraph_contentObj = getGraphContentObj({
        title:"TestGraph1", 
        graphTypeObj:testGraph_graphType,
        initGraphState: testGraph_initGraphState
    })

    return testGraph_contentObj;
}




