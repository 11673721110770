//020721
// Creating an Option class to represent all data for a single option, and contain related methods.
// Hopefully, this will allow for cleaner React code (eliminate passing JSON and individual props around)
//  and also eliminate state issues.

import * as bs from 'black-scholes'
import v4 from 'uuid/dist/v4'

const uuid = v4;

//020721
// - Going to list static option methods (or just util funcs inside here) that take args in json.
//      The optionPosition can utilize those with it's own values, and we should be able to also allow user
//      to pass through specific constants


//103121
// Todo: Rename OptionPosition classes to Contract

export default class OptionPosition {
    // represents information about an Option position and related functions
    constructor(underlyingPrice=100, buySell="buy", callPut="call", strike=90, daysToExpiration=30, volatility=0.5, riskFreeRate=0.01 ){
        this.id = uuid();
        this.underlyingPrice = parseFloat(underlyingPrice); //underlyingPrice;
        this.buySell = buySell;
        this.callPut = callPut;
        this.strike = parseFloat(strike);
        this.daysToExpiration = parseFloat(daysToExpiration);
        this.volatility = parseFloat(volatility);
        this.riskFreeRate = parseFloat(riskFreeRate);
    }

    getPremium = (args) => { //TODO: change to getValue()
        return OptionMethods.getPremium({...this, ...args})
    }

    getCreditDebit = (args) => {
        // returns net credit or debit amount based on option premium and the optionDirection
        return OptionMethods.getCreditDebit({...this, ...args})
    }

    getIntrinsicValue = (args) => {
        // returns intrinsic value of an option given the params
        return OptionMethods.getIntrinsicValue({...this, ...args})
    }

    getExtrinsicValue = (args) =>{
        return OptionMethods.getExtrinsicValue({...this, ...args})
    }

    getPL = (args) => {
        // returns PL of an option
        if(!args) args={}
        args.creditDebit = this.getCreditDebit(); // use current premium.
        return OptionMethods.getPL({...this, ...args})
    }

    getPLAtExpiration = (args) => {
        // returns PL of an option if underlying is at underlyingPrice at expiration
        if(!args) args={}
        args.creditDebit = this.getCreditDebit(); // use current premium.
        return OptionMethods.getPLAtExpiration({...this, ...args})
    }

    getDelta = (args, priceDiff=1, adjustForPositionType=false) => {
        // returns Delta (change in option value over priceDiff change in underlying price)
        //console.log("OptionPosition.getDelta() > ", args)
        return OptionMethods.getDelta({...this, ...args}, priceDiff, adjustForPositionType);
    }

    getGamma = (args, priceDiff=1, adjustForPositionType=false) => {
        // returns Gamma (change in delta over priceDiff change in underlying price)
        return OptionMethods.getGamma({...this, ...args}, priceDiff, adjustForPositionType);
    }

    getVega = (args, volDiff=0.01, adjustForPositionType=false) => {
        // returns Vega (change in option value over volDiff change in volatility)
        return OptionMethods.getVega({...this, ...args}, volDiff, adjustForPositionType);
    }

    getTheta = (args, daysDiff=-1, adjustForPositionType=false) => {
        // returns Theta (change in option value over daysDiff change in time)
        return OptionMethods.getTheta({...this, ...args}, daysDiff, adjustForPositionType);
    }

    getRho = (args, rateDiff=0.01, adjustForPositionType=false) => {
        // returns Rho (change in option value over rateDiff change in riskFreeRate)
        return OptionMethods.getRho({...this, ...args}, rateDiff, adjustForPositionType);
    }

    getGreeks = (args, adjustForPositionType=false) => {
        // returns json of greeks; adjustForPositionType will invert the greeks if this is a short option.
        // console.log("OptPos.getGreeks().adjust > ", adjustForPositionType)
        return OptionMethods.getGreeks({...this, ...args}, adjustForPositionType);
    }

    getMaxLoss = (args) => {
        // returns maxLoss or null if infinite
        return OptionMethods.getMaxLoss({...this, ...args});
    }

    getMaxProfit = (args) => {
        // returns max profit or undefined if infinite
        return OptionMethods.getMaxProfit({...this, ...args});
    }

    getExpectedValue = (args) => {
        // returns expected value if loss is not infinite
        return OptionMethods.getExpectedValue({...this, ...args});
    }

    getProbITM(args) {
        // returns probability of expiring ITM (calculates using delta);
        return OptionMethods.getProbITM({...this, ...args});
    }

    getProbOTM(args) {
        // returns probability of expiring OTM (calculates using delta);
        return OptionMethods.getProbOTM({...this, ...args});
    }

    getUnderlyingPriceAtBreakeven(args) {
        // returns the price that the underlying needs to be at expiration in order to break even
        if(!args) args={}
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : this.getCreditDebit();
        return OptionMethods.getUnderlyingPriceAtBreakeven({...this, ...args});

    }

    getProbabilityOfProfit(args) {
        // returns POP -> probability of expiring with at least 0.01 profit.
        if(!args) args={}
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : this.getCreditDebit();
        return OptionMethods.getProbabilityOfProfit({...this, ...args, creditDebit});
    }

    // getCopy() {
    //     // returns a new OptionPosition Obj with all the same values of this one. Useful temporary solution for modifying values to chart
    //     return new OptionPosition(this.underlyingPrice,this.buySell,this.callPut,this.strike,this.daysToExpiration,this.volatility,this.riskFreeRate);
    // }
}


export class OptionMethods {

    static _getYearsFromDTE(daysToExpiration) {
        /** Returns  years as float from given daysToExpiration. e.g. 365 returns 1, */
        return parseFloat(daysToExpiration/365.0);
        // const yearsInOneDay = 0.002738;
        // return parseFloat(daysToExpiration*yearsInOneDay)
    }

    static getPremium(args) {
        const t = OptionMethods._getYearsFromDTE(args.daysToExpiration);
        return bs.blackScholes(args.underlyingPrice, args.strike, t, args.volatility, args.riskFreeRate, args.callPut);
    }

    static getCreditDebit(args) {
        // returns net credit or debit amount based on option premium and the optionDirection
        let premium = OptionMethods.getPremium(args);
        return (args.buySell=="buy") ? -premium : premium;
    }

    static getIntrinsicValue(args) {
        // returns intrinsic value of an option given the params
        var intrinsicValue = (args.callPut=="call") ? parseFloat(args.underlyingPrice) - args.strike : args.strike - args.underlyingPrice;
        if(intrinsicValue<0) intrinsicValue = 0; // ensure min zero.
        return intrinsicValue;
    }

    static getExtrinsicValue(args) {
        return OptionMethods.getPremium(args) - OptionMethods.getIntrinsicValue(args);
    }

    static getPL = (args) => {
        // returns PL of an option
        var optionValue = parseFloat(OptionMethods.getPremium(args));
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : OptionMethods.getCreditDebit(args);
        var pl = (args.buySell=="buy") ? optionValue + creditDebit: -optionValue + creditDebit;
        return pl;
    }
    
    static getPLAtExpiration(args) {
        // returns PL of an option if underlying is at underlyingPrice at expiration
        var intrinsicValue = (args.intrinsicValue!=null) ? parseFloat(args.intrinsicValue) : OptionMethods.getIntrinsicValue(args);
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : OptionMethods.getCreditDebit(args);
        var pl = (args.buySell=="buy") ? intrinsicValue + creditDebit: -intrinsicValue + creditDebit;
        return pl;
    }

    static getDelta(args, underlyingDiff=1, adjustForPositionType=false) {
        // returns delta (change in option value over change of priceDiff in underlying price)
        const curUnderlying = parseFloat(args.underlyingPrice);
        const newUnderlying = parseFloat(curUnderlying+underlyingDiff); //old
        // const newUnderlying = curUnderlying+(curUnderlying*0.01)
        const curOptionValue = OptionMethods.getPremium(args);
        const newOptionValue = OptionMethods.getPremium({...args, underlyingPrice: newUnderlying});
        const diffOptionValue = curOptionValue - newOptionValue;
        const diffUnderlying = curUnderlying- newUnderlying; 
        let delta = (diffOptionValue/diffUnderlying);

        //debug
        //console.log("OptionPosition.getDelta() DEBUG:", {curUnderlying,newUnderlying,curOptionValue,newOptionValue,diffOptionValue,diffUnderlying,delta, args})

        if(adjustForPositionType && args.buySell) delta *= ((args.buySell=="buy") ? 1 : -1);
        return delta;
    }

    static getGamma(args, underlyingDiff=1, adjustForPositionType=false) {
        // returns gamma (change in delta over priceDiff change in underlying price)
        const curUnderlying = parseFloat(args.underlyingPrice);
        const newUnderlying = curUnderlying+parseFloat(underlyingDiff);
        const curDelta = OptionMethods.getDelta(args, underlyingDiff);
        const newDelta = OptionMethods.getDelta({...args, underlyingPrice: newUnderlying});
        const diffDelta = newDelta - curDelta;
        const diffUnderlying = newUnderlying - curUnderlying;
        let gamma = (diffDelta/diffUnderlying);
        if(adjustForPositionType && args.buySell) gamma *= ((args.buySell=="buy") ? 1 : -1);
        return gamma;
    }

    static getVega(args, volDiff=0.01, adjustForPositionType=false) {
        // returns Vega (change in option value over volDiff change in volatility)
        const curVolatility = parseFloat(args.volatility);
        const newVolatility = curVolatility+parseFloat(volDiff);
        const curOptionValue = OptionMethods.getPremium(args);
        const newOptionValue = OptionMethods.getPremium({...args, volatility: newVolatility});
        const diffVolatility = (newVolatility - curVolatility)*100; //multipl by 100 to convert to pct before final calculation
        const diffOptionValue = newOptionValue - curOptionValue;
        let vega = (diffOptionValue/diffVolatility);
        if(adjustForPositionType && args.buySell) vega *= ((args.buySell=="buy") ? 1 : -1);
        return vega;
    }

    static getTheta(args, daysDiff=-1, adjustForPositionType=false) {
        // returns Theta (change in option value over daysDiff change in time)
        const curDaysToExpiration = parseFloat(args.daysToExpiration);
        const newDaysToExpiration = curDaysToExpiration+parseFloat(daysDiff);
        const curOptionValue = OptionMethods.getPremium(args);
        const newOptionValue = OptionMethods.getPremium({...args, daysToExpiration: newDaysToExpiration});
        const diffOptionValue = newOptionValue - curOptionValue;
        const diffDaysToExpiration = newDaysToExpiration - curDaysToExpiration;
        let theta = (diffOptionValue/diffDaysToExpiration);

        // console.log("getTheta().curDte", curDaysToExpiration)
        // console.log("getTheta().newDte", newDaysToExpiration)
        // console.log("getTheta().diffDte", diffDaysToExpiration)
        // console.log("getTheta().curVal", curOptionValue)
        // console.log("getTheta().newVal", newOptionValue)
        // console.log("getTheta().diffVal", diffOptionValue)

        if(diffDaysToExpiration<0) theta *=-1; // TODO: above calculation yield positive theta. Cannot figure out how best to calculate true negative theta atm. adding this here as a placeholder.

        if(adjustForPositionType && args.buySell) theta *= ((args.buySell=="buy") ? 1 : -1);
        return theta;
    }

    static getRho(args, rateDiff=0.01, adjustForPositionType=false) {
        // returns Rho (change in option value over rateDiff change in riskFreeRate)
        const curRiskFreeRate = parseFloat(args.riskFreeRate);
        const newRiskFreeRate = curRiskFreeRate+parseFloat(rateDiff);
        const curOptionValue = OptionMethods.getPremium(args);
        const newOptionValue = OptionMethods.getPremium({...args, riskFreeRate: newRiskFreeRate});
        const diffOptionValue = newOptionValue - curOptionValue;
        const diffRiskFreeRate = (newRiskFreeRate - curRiskFreeRate) *100; // multiply by 100 to convert pct before final calc
        let rho = (diffOptionValue/diffRiskFreeRate);
        if(adjustForPositionType && args.buySell) rho *= ((args.buySell=="buy") ? 1 : -1);
        return rho;
    }


    static getGreeks(args, adjustForPositionType=false) {
        // returns json of greeks
        let greeks = {
        "delta": OptionMethods.getDelta(args, undefined, adjustForPositionType), 
        "gamma": OptionMethods.getGamma(args, undefined, adjustForPositionType), 
        "vega": OptionMethods.getVega(args, undefined, adjustForPositionType), 
        "theta": OptionMethods.getTheta(args, undefined, adjustForPositionType), 
        "rho": OptionMethods.getRho(args, undefined, adjustForPositionType) 
        };          //TODO: the way you are passing these params is messy. Struggled for a bit not being able to assign values via param names.. look into more robust/safe/readable method for sending the adjustForPositionType param without needing to have it in the right position
        return greeks;
    }


    static getMaxLoss(args) {
        // returns maxLoss or undefined if infinite
        
        // if buy, loss=premium * qty
        // if sell call, loss=undefined
        // if sell put, loss=(distance to 0 from strike - credit) * qty
        if(args.buySell=="buy") return OptionMethods.getPremium(args);
        if(args.callPut=="put") return parseFloat(args.strike) - OptionMethods.getPremium(args);
        return undefined;
    }

    static getMaxProfit(args) {
        // returns max profit or undefined if infinite
        // if buy call, profit = undefined
        // if buy put, profit = distance to 0 + debit
        // if sell, profit = premium

        if(args.buySell!="buy") return OptionMethods.getPremium(args);
        if(args.callPut=="put") return parseFloat(args.strike) + OptionMethods.getPremium(args);
        return undefined;
    }

    static getExpectedValue(args) {
        // returns expected value if loss is not infinite
            // uses delta as probability
        //TODO: this is currently just going to be calculating EV based on prob ITM... should make a variant that calculates EV based on prop Profit (depends on premium)

        // EV = P(gain)*gain + P(loss)*loss
        // P(gain) = probITM if buy, or probOTM if sell
        //  probITM = delta; probOTM = 1-delta
        // P(loss) = probOMT if buy, or probITM if  sell
        
        const delta = Math.abs(OptionMethods.getDelta(args));
        const maxProfit = OptionMethods.getMaxProfit(args);
        const maxLoss = OptionMethods.getMaxLoss(args);
        if(maxProfit==undefined || maxLoss==undefined) return undefined;

        const probWin = (args.buySell=="buy") ? OptionMethods.getProbITM(args) : OptionMethods.getProbOTM(args);
        const probLoss = 1-probWin;
        const ev = probWin*maxProfit + probLoss*maxLoss;
        return ev;        
    }

    static getProbITM(args) {
        // returns probability of expiring ITM (calculates using delta);
        return Math.abs(OptionMethods.getDelta(args));
    }

    static getProbOTM(args) {
        // returns probability of expiring OTM (calculates using delta);
        return 1-Math.abs(OptionMethods.getDelta(args));
    }

    static getUnderlyingPriceAtBreakeven(args) {
        // returns the price that the underlying needs to be at expiration in order to break even
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : OptionMethods.getCreditDebit(args);
        return (args.callPut=="call") ? parseFloat(args.strike) + Math.abs(creditDebit) : parseFloat(args.strike) - Math.abs(creditDebit);

    }

    static getProbabilityOfProfit(args) { //TODO: need to verify
        // returns POP -> probability of expiring with at least 0.01 profit.
        var creditDebit = (args.creditDebit!=null) ? parseFloat(args.creditDebit) : OptionMethods.getCreditDebit(args);
        const breakeven = OptionMethods.getUnderlyingPriceAtBreakeven({...args, creditDebit});
        const profitPrice = breakeven + (args.callPut=="call" ? 0.01 : -0.01);

        const propITM = OptionMethods.getProbITM({...args, strike: profitPrice});
        const probOTM = OptionMethods.getProbOTM({...args, strike: profitPrice})

        // console.log("breakeven", breakeven);
        // console.log("profitPrice", profitPrice);
        // console.log("probITM At profitPrice() > ", propITM);
        // console.log("probOTM At profitPrice() > ", probOTM);
        return args.buySell=="buy" ? propITM : probOTM;
    }

}