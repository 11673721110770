// 021321
// Component for the bounds and dataIncrement (zoom) UI to allow user to modify applicable graphs
//  -This may not be generalized enough for all graphs but I wanted to extract it instead of having a copy in each graph component.

import {useState} from 'react';

function GraphControlsUI({graphControlSettings, settingsModifiedCallback, granularityOptions=[0.10, 0.25, 0.5, 1, 5]}) {

    const updateGraphControlInput = (args) => {
        settingsModifiedCallback({...graphControlSettings, ...args});
    }

    const getReverseDataSwitchElement = () => {
        return (
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={graphControlSettings.reverseData} onChange={(e)=>updateGraphControlInput({reverseData: e.target.checked})}/>
                <label class="form-check-label" for="flexSwitchCheckChecked">Invert X axis</label>
            </div>
        )
    }
    
    return (
        <div id="graphControls">
            <label htmlFor="boundsSlider">Zoom -+</label>
            <input type="range" id="boundsSlider" min={1} max={100} step={1} value={graphControlSettings.bounds} onChange={(e)=>updateGraphControlInput({bounds:parseFloat(e.target.value)})} style={{direction:"rtl"}}/>
            {/* {bounds} */}
            <br/>
            <label htmlFor="granularity">Granularity (lower=slower)</label>
            <select id="granularity" onChange={(e)=>updateGraphControlInput({granularity:parseFloat(e.target.value)})} value={graphControlSettings.granularity} >
                {granularityOptions.map(i => <option key={i} value={i}>{i}</option>)}
            </select>
            {getReverseDataSwitchElement()}
        </div>
    )
}

export default GraphControlsUI;
