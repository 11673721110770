
// 020721
// Option Position Component

import './OptionPositionComponent.css'
import '../util/formatUtil'
import { toFixedCst } from '../util/formatUtil';

function OptionPositionComponent({position, updatePosition, removePositionById}) {

  const onInputChanged = (e) => {
        const val = e.target.value;
        if (!e.target.id=="buySell" && !e.target.id=="callPut") {val = parseFloat(val)} // this doesn't fix the string outout problem
        position[e.target.id]=val;
        updatePosition(position);
    }

  // const getSubLineInfo = (label, data) => {
  //   // generates and returns element to be shown in subline info area (under user input)
  //   return (
  //     <div className="col-1 position-relative">
  //       <div className="greeksTitle position-absolute top-0">{label}</div>
  //       <div className="greeksData position-absolute bottom-0">{data}</div>
  //     </div>
  //   )
  // } 

  const uiContainerClass = "form-floating col p-0";
  const inputElementClass = "form-control";
  const selectElementClass = "form-select";

  const getSubLineInfo = (label, data) => {
    // generates and returns element to be shown in subline info area (under user input)
    return (
      <div className="col">
        <span className="greeksTitle">{label}</span>
        <span className="greeksData">{data}</span>
      </div>
    )
  } 

  const getSelectUI = (id, placeholder, selectOptions, value) => {
    // generates and returns selection element. selectOptions = {DisplayStr1: optionValue1, ..., etc.}
    return (
      <div className={uiContainerClass}>
        <select className={selectElementClass} id={id} value={value} onChange={onInputChanged} >
          {Object.keys(selectOptions).map((k)=> {
            return (<option key={k} value={selectOptions[k]}>{k}</option>)
          })}
        </select>
        <label htmlFor={id}>{placeholder}</label>
      </div>
    )
  }

  const getInputUI = (id, placeholder, value, step=1) => {
    // generates and returns element for input
    let inputClass = inputElementClass;
    if(value.length==0) inputClass += " is-invalid";
    return (
      <div className={uiContainerClass}>
        <input type="number" id={id} className={inputClass} placeholder={placeholder} value={parseFloat(value)} onChange={onInputChanged} step={step}/>
        <label htmlFor={id}>{placeholder}</label>
      </div>
    )
  }


    return (
      <div className="row mb-1">
        {/* <div className="col"> */}

          <div className="row flex-nowrap">
            {getSelectUI('buySell', 'Buy/Sell', {'Buy': 'buy', 'Sell': 'sell'}, position.buySell)}
            {getSelectUI('callPut', 'Call/Put', {'Call': 'call', 'Put': 'put'}, position.callPut)}

            {getInputUI('underlyingPrice', 'Underlying', position.underlyingPrice)}
            {getInputUI('strike', 'Strike', position.strike)}
            {getInputUI('daysToExpiration', 'DTE', position.daysToExpiration)}
            {getInputUI('volatility', 'Volatility %', position.volatility,0.1)}
            {getInputUI('riskFreeRate', 'Rate %', position.riskFreeRate,0.01)}
          </div>

          <div className="greeksRow row bg-light bg-gradient flex-nowrap">
            {getSubLineInfo("Cr/Db:", position.getCreditDebit().toFixed(2))}
            {getSubLineInfo("Breakeven:", position.getUnderlyingPriceAtBreakeven ().toFixed(2))}
            
            {/* <li className="list-inline-item separator"></li> */}

            {getSubLineInfo("Delta: ", position.getDelta().toFixed(3))}
            {getSubLineInfo("Gamma: ", position.getGamma().toFixed(3))}
            {getSubLineInfo("Vega: ", position.getVega().toFixed(3))}
            {getSubLineInfo("Theta: ", position.getTheta().toFixed(3))}
            {getSubLineInfo("Rho: ", position.getRho().toFixed(3))}

            {/* <li className="list-inline-item separator"></li> */}

            {getSubLineInfo("Max Profit: ", toFixedCst(position.getMaxProfit(), 2))}
            {getSubLineInfo("Max Loss: ", toFixedCst(position.getMaxLoss(), 2))}
            {getSubLineInfo("EV: ", toFixedCst(position.getExpectedValue(), 2))}
            {getSubLineInfo("POP: ", toFixedCst(position.getProbabilityOfProfit(), 2))}
          </div>

        {/* </div> */}

        <button className="btn btn-close col-1" id="removeButton" onClick={(e)=>removePositionById(position.id)}/>

      </div>
    );

    // return (
    //   <>
        
    //     <td headers="buysell">
    //       <select id="buySell" value={position.buySell} onChange={onInputChanged}>
    //           <option value="buy">Buy</option>
    //           <option value="sell">Sell</option>
    //       </select>
    //     </td>

    //     <td headers="callput">
    //       <select id="callPut" value={position.callPut} onChange={onInputChanged}>
    //           <option value="call">Call</option>
    //           <option value="put">Put</option>
    //       </select>
    //     </td>

    //     <td headers="underlyingprice">
    //       <input id='underlyingPrice' type="number" placeholder='Current Underlying Price' value={parseFloat(position.underlyingPrice)} onChange={onInputChanged}/>
    //     </td>

    //     <td headers="strike">
    //       <input id='strike' type="number" placeholder='Strike Price' value={position.strike} onChange={onInputChanged}/>
    //     </td>

    //     <td headers="dte">
    //       <input id='daysToExpiration' type="number" placeholder='DTE' value={position.daysToExpiration} onChange={onInputChanged}/>
    //     </td>

    //     <td headers="vol">
    //       <input id='volatility' type="number" placeholder='Volatility %' value={position.volatility} onChange={onInputChanged}/>
    //     </td>

    //     <td headers="rate">
    //       <input id='riskFreeRate' type="number" placeholder='Risk Free Rate %' value={position.riskFreeRate} onChange={onInputChanged}/>
    //     </td>

    //     <td headers="value">
    //       {position.getPremium().toFixed(2)}
    //     </td>

    //     <td headers="creditdebit">
    //       {position.getCreditDebit().toFixed(2)}
    //     </td>
    //     <br/>
    //     <span>
    //       <p>Delta: {position.getDelta().toFixed(3)}</p>
    //       <p>Gamma: {position.getGamma().toFixed(3)}</p>
    //       <p>Vega: {position.getVega().toFixed(3)}</p>
    //       <p>Theta: {position.getTheta().toFixed(3)}</p>
    //       <p>Rho: {position.getRho().toFixed(3)}</p>
    //       <p>Max Profit: {position.getMaxProfit()}</p>
    //       <p>Max Loss: {position.getMaxLoss()}</p>
    //       <p>EV: {position.getExpectedValue()}</p>
    //     </span>

    //     {/*id: {position.id}*/}
    //   </>
    // );
  }

export default OptionPositionComponent;