//031021
// Modular Content Navbar
//  Component that allows user to choose from different types of content and will display various navbar menus



export function ModularContentNavbar({id, activeContentKey, contentOptionsObj, onContentSelected, setWidgetState}) {

        let selectedContent = contentOptionsObj[activeContentKey];
        let navbarContent = selectedContent.widgets;

        return (

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">

                    <div className="dropdown preventDrag">
                    <button className="btn navbar-brand dropdown-toggle" href="#" id="navbarGraphTypeDropdown" role="button" data-bs-toggle="dropdown">{selectedContent.title}</button>
                        <ul className="dropdown-menu" aria-labelledby="navbarGraphTypeDropdown">
                                {Object.keys(contentOptionsObj).map((k)=>{
                                    return (<li className="dropdown-item" key={k} id={k} onClick={(e)=>onContentSelected(k)}>{contentOptionsObj[k].title}</li>)
                                })}
                        </ul>
                    </div>
                    <button className="navbar-toggler preventDrag" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">

                        {(navbarContent==null) ? <></> : 
                            
                            Object.keys(navbarContent).map((k)=>{
                                let TempComponent = navbarContent[k].component; // var name must be uppercase for JSX to parse as component
                                let tempProps = navbarContent[k].props;
                                let tempState = navbarContent[k].state;
                                let tempId = navbarContent[k].id;
                                let temptitle = navbarContent[k].title;
                                return (
                                    <li id={tempId} key={tempId} className="nav-item dropdown preventDrag">
                                        <button className="btn nav-link dropdown-toggle" href="#" id="navbarSettingsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {temptitle}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="navbarSettingsDropdown">
                                            <li><TempComponent {...tempProps} {...navbarContent[k]} setState={(newState)=>setWidgetState(k,newState)}/> </li>
                                        </ul>  
                                    </li>
                                )
                            })
                            
                        }


                        <li className="nav-item d-inline-flex position-absolute end-0">
                            {/* Couldn't get static to work in the grid, so disabling this switch for now */}
                            {/* <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="lockGraphSwitch" checked={graphState.gridLayout.static} onChange={(e)=>updateGraphsGridLayout({static: Boolean(e.target.checked)})}/>
                                <label class="form-check-label" for="lockGraphSwitch">Lock</label>
                            </div> */}
                            {/* <button className="nav-item btn btn-close preventDrag" onClick={()=>updateGraphState({},true)}></button> */}
                        </li>

                    </ul>
                    </div>
                </div>
            </nav>
        )
    }