//031521
// Custom JSON stringify and Parse methods


import {useRef} from 'react';
import {objectBank} from './ObjectBank'

console.log("objectBank> ", objectBank)

//let objectBank = {}

export function BetterJSON() {


    this.stringify=(input, jsonStringifyOutput=true)=> {
        // Custom stringify obj which can prep funcs and objs to be restored using this.parse
        let obj = {...input}
    
        const stringifyItem = (item) => {
            let str = item;
            if(item==null) str = item;
            else if(typeof(item)==='object') str = this.stringify(item, false);
            else if(typeof(item)==='function') {objectBank[item.name]=item; str = '~f>'+item.name;}
            return str;
        }
    
        for(let [k,v] of Object.entries(obj)) {
            v = stringifyItem(v);
            k = stringifyItem(k);
            obj={...obj,[k]:v}
        }
    
        if(Array.isArray(input)) obj=Object.values(obj); //convert back to array if input was array.
        return (jsonStringifyOutput) ? JSON.stringify(obj) : obj;
    }
    
    this.parse=(input)=>{ //todo: see if you can consolidate this down to just use in the JSON.parse() reviver param.
        // custom parse method; input can be object or json
        let obj = (typeof(input)=='string') ? JSON.parse(input) : input;
        let isArray =Array.isArray(obj);
        
        const parseItem = (item) => {
            let newItem = item;
            
            if(typeof(newItem)==='object' && newItem!=null) newItem = this.parse(newItem);
            else if(typeof(newItem)=='string' && newItem[0]==='~') {
                 //console.log("parseItem > key >", newItem)
                newItem = objectBank[newItem.split('>')[1]] //{}.constructor =`${newItem.split('>')[1]}` //Object.setPrototypeOf({}, `${newItem.split('>')[1]}`) //{}.constructor = newItem.split('>')[1]; //func or obj //// 
                //  console.log("parseItem > ", newItem)
                //  console.log("parseItem > ", newItem.prototype)
            }
            
            return newItem;
        }
    
        for(let [k,v] of Object.entries(obj)) {
            v = parseItem(v);
            k = parseItem(k);
            obj={...obj,[k]:v}
        }
        if(isArray) obj=Object.values(obj); //convert back to array if input was array.
        return obj;
    }
}


//========


