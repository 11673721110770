//030721
// Module containing StateArray objects


// TODO: 031321 : need to test all callbacks.

import {useState, useEffect} from 'react';
import {StorableState, Storable} from '../../util/storage/StorageUtil'


export function StateArray({initItems=[], callbacks={getNewItem:undefined,onItemAdded:undefined,onItemUpdated:undefined,onItemRemoved:undefined,onAnyChange:undefined}}) {
    // object that can be instantiated to contain a stateful array with useful methods to pass to components in order to update it.
    //todo: 030721 if this doesn't work using internal state, try passing state and it's setter and modify it directly.

    this.callbacks=callbacks;
    [this.items, this.setItems] = useState(initItems);
    useEffect(()=>this.makeCallback(callbacks.onAnyChange, [this.items]), [this.items]);
    

    this.addItem = (newItem) => {
        // adds new item; If newItem is undefined, a callback to getNewItem() will be attempted.
        if(newItem===undefined) {newItem=this.makeCallback(callbacks.getNewItem)};
        this.setItems(()=>[...this.items,newItem]);
        this.makeCallback(callbacks.onItemAdded, {newItem});
    };

    this.setItem = (updatedItem) => {
        // updates an item
        this.setItems(()=>this.items.map(i=>{
            return updatedItem.id===i.id ? updatedItem : i;
        }));
        this.makeCallback(callbacks.onItemUpdated, {updatedItem});
    };

    this.setItemById = (id, updatedItem) => {
        // updates an item by id
        this.setItems(()=>this.items.map(i=>{
            return id===i.id ? updatedItem : i;
        }));
        this.makeCallback(callbacks.onItemUpdated, {updatedItem});
    };

    this.setProperty = (id,newProperty) => {
        // adds or overwrites newProperty in item with id; {...currentItem, ...newProperty}
        this.setItems(()=>this.items.map(i=>{
            return id===i.id ? {...i, ...newProperty} : i;
        }));
        this.makeCallback(callbacks.onItemUpdated, {id});
    };

    this.setPropertyByBatch = (batchObj) => {
        // adds or overwrites properties of multiple items in a batchObj. batchObj should be {id1: newProperties, id2: newProperties}
        this.setItems(()=>this.items.map(i=>{
            return (batchObj.hasOwnProperty(i.id)) ? {...i, ...batchObj[i.id]} : i;
        }));
        for(let k of Object.keys(batchObj)) this.makeCallback(callbacks.onItemUpdated, {k});
    };


    this.setPropertyByPath = (id, propPath='', newValue) => { //todo: test
        // updates a property while preserving existing values on the same level; propPath should be string. e.g. to update Obj.lev1.lev2, use "lev1.lev2"
        let propPathArray = propPath.split('.');
        let targetProp = propPathArray.slice(-1);
        let obj, curObj = this.getItemById(id);
    
        for(let i of propPathArray.slice(0,-1)) { 
            if(i.length===0) continue;
            if( !curObj[i] ) curObj[i] = {};
            curObj = curObj[i]; //acts as reference pointer in obj
        }
        curObj[targetProp] = newValue; 
        this.setItem(obj);
    };

    this.getPropertyByPath = (id, propPath='') => {
        let propPathArray = propPath.split('.');
        let curObj = this.getItemById(id);
        for(let i of propPathArray) {
            if(i.length==0) continue;
            curObj = curObj[i];
        }
        return curObj;
    }

    this.removeById = (id) => {
        // removes item by id
        this.setItems(()=>this.items.filter(i=>{
            if(id!==i.id) return i;
        }));
        this.makeCallback(callbacks.onItemRemoved, {id});
    };

    this.getItemById = (id) => {
        // returns an item by id
        this.items.map(i=>{
            if(id===i.id) return i;
        })
    };

    this.makeCallback = (callback, params) => {
        // attempts to make a call back and returns the result
        if(callback!=undefined) return (params==undefined) ? callback() : callback(...params);
    };
}

export function StorableStateArray({id, parseFunc, initItems=[], callbacks={getNewItem:undefined,onItemAdded:undefined,onItemUpdated:undefined,onItemRemoved:undefined,onAnyChange:undefined}})  {
    const storable = new Storable({id,parseFunc});
    if(initItems.length==0) initItems = storable.load('[]');
    //console.log("StorableStateArray > initItems", initItems)
    StateArray.call(this, {initItems,callbacks});
    useEffect(()=>storable.save(this.items),[this.items]);
}


// export function GridItemStateArray({initItems=[], callbacks={getNewItem:undefined,onItemAdded:undefined,onItemUpdated:undefined,onItemRemoved:undefined,onAnyChange:undefined}}) { // extends StateArray
//     // a sub-prototype of StateArray made for gridItem-shaped objects
//     // {id,gridItemConfig,gridConfig, content:{id,component,props,state, navbarContent}}

//     StateArray.call(this, {initItems,callbacks});

//     this.setGridItemConfig = (gridItemId,newGridItemConfig) => {
//         // sets new gridItemConfig
//         let cur = this.getItemById(gridItemId);
//         this.setProperty(gridItemId, {gridItemConfig:{...cur.gridItemConfig,...newGridItemConfig}});
//     };

//     this.setContentComponent = (gridItemId,newComponent) => {
//         // sets new component //todo: probably need to check if using a spread here is wise.. newComponent will likely be a single component.
//         this.setProperty(gridItemId, {content:{component:{...newComponent}}});
//     };

//     this.setContentProps = (gridItemId,newProps) => {
//         // sets new props
//         this.setProperty(gridItemId, {content:{props:{...newProps}}});
//     };

//     this.setContentState = (gridItemId,newState) => {
//         // sets new state
//         this.setProperty(gridItemId, {content:{state:{...newState}}});
//     };

//     this.setContentStateProperty = (gridItemId,propName, newValue) => {
//         // sets state property
//         this.setProperty(gridItemId, {content:{state:{[propName]:{...newValue}}}});
//     };

//     this.updateContentStateProperty = (gridItemId,propName, newValue) => {
//         // updates state property
//         let cur = this.getItemById(gridItemId);
//         this.setProperty(gridItemId, {content:{state:{[propName]:{...cur.content.state[propName],...newValue}}}});
//     };


//     this.updateContentProperty = (gridItemId,propName, newValue) => { //todo: test
//         // updates a content property
//         let cur = this.getItemById(gridItemId);
//         this.setProperty(gridItemId, {content:{[propName]:{...cur.content[propName],...newValue}}});
//     };

//     this.setNavbarContent = (gridItemId,newNavbarContent) => {
//         // sets new navbar content
//         let cur = this.getItemById(gridItemId);
//         this.setProperty(gridItemId, {content:{navbarContent:{...cur.content.navbarContent,...newNavbarContent}}});
//     };

//     this.updateNavbarContentProperty = (gridItemId,navbarContentKey, propName, newValue) => {
//         // updates a navbar content property
//         this.setPropertyByPath(gridItemId,'content.navbarContent.'+navbarContentKey+'.'+propName,newValue);
//         //this.setProperty(gridItemId, {content:{navbarContent[navbarContentKey].props:{...cur.content.navbarContent,...newNavbarContent}}});
//     };



// }
