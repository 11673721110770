//022721
// workspace for developing tools using api data.

//

import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Legend, ComposedChart, Text } from 'recharts';
import GridLayout from 'react-grid-layout';

import {useState, useEffect, useRef} from 'react'
import v4 from 'uuid/dist/v4'

import * as tda from '../../api wrappers/tdameritrade/tdameritrade'
import OptionPosition from '../../classes/Option';
import { cloneLayout } from 'react-grid-layout/build/utils';

const uuid = v4;


export default function APIDataTest() {

    const [optionChainData, setOptionChainData] = useState();
    const [gridState, setGridState] = useState({nCols: 20, rowHeight: 120, width: 1800});
    const [graphs, setGraphs] = useState([getNewGraph()]);
    const [nGraphUpdates, setNGraphUpdates] = useState(0);
    const [currentSymbol, setCurrentSymbol] = useState("SPY");
    const [intervalId, setIntervalId] = useState();

    // let symbolInput = useRef();
    // let intervalId = useRef();
    //symbolInput.current.value = "Test"

    function getNewGraph() {
        return {
            id: uuid(),
            //axisOptions, 
            activeAxis: {x: 'underlyingPrice',y: 'PL at Expiration' },
            //data: [],
            //graphType: graphTypes[graphType],
            //graphControlSettings: {bounds: 20, granularity: 1, reverseData: false},
            gridLayout: {x: 0, y: 0, w: 5, h: 3, minH: 3, maxH: 10, minW:5, static: false},
            //gridState: gridState
        }
    }

    function addNewGraph() {
        const newGraphs = graphs;
        newGraphs.push(getNewGraph());
        setGraphs(newGraphs);
        setNGraphUpdates(nGraphUpdates+1);
        //console.log("apiDataTest.addNewGraph graphs > ", graphs)
    }

    function modifyGraphs(id, action, params) { 
        console.log("modifygraphs")
        switch(action.toUpperCase()) {
            case "REMOVE": { setGraphs((cur)=>cur.filter((i)=>i.id!=id)); break;}
            default: { }
        }
    }

    function updateOptionChain() {
        //symbol = document.getElementById("symbolInput").value; //todo: replace with form and submit button
        //if(currentSymbol.length==0) return;
        // console.log("getOptionChain", symbolInput.current.value)
        // console.log("getOptionChain > CV", currentSymbol)
        tda.getOptionChainData(currentSymbol, 75).then( 
            (data)=> {
                setOptionChainData(data);
            }
        );
    }

    function updateCurrentSymbol() {
        updateOptionChain()
        clearInterval(intervalId);
        setIntervalId(setInterval(updateOptionChain, 5000))
    }

    useEffect(()=>{ //INIT
        updateCurrentSymbol()
        return ()=> {clearInterval(intervalId);} //todo: not working; fix so that interval is not being called when unmounted.
    },[])

    //console.log(optionChainData)
    return (
        <>
            <div>
                <input id="symbolInput" type="text" value={currentSymbol} onChange={(e)=>setCurrentSymbol(e.target.value)}/>
                <button className="btn btn-primary" onClick={updateCurrentSymbol}>go</button>
            </div>
            {/* {(optionChainData) ? <DynamicOptionApiGraph formattedOptionChainData={optionChainData}/> : <>No Symbol Loaded</>} */}

            <button onClick={addNewGraph}>New Graph</button>

            {(optionChainData) ? 
                <GridLayout className="layout" 
                    cols={gridState.nCols} rowHeight={gridState.rowHeight} width={gridState.width} 
                    //onResizeStop={(layout)=>udpateGraphPropsById(layout[0].i,{gridLayout: layout[0]})}
                    verticalCompact={true}
                    compactType='horizontal'>
                    {graphs.map((i)=> {
                        // console.log(i)
                        return <div className="border rounded-3" id={i.id} key={i.id} data-grid={i.gridLayout}> 
                                <DynamicOptionApiGraph id={i.id} formattedOptionChainData={optionChainData} modifyGraphs={modifyGraphs}/>
                            </div>
                    })}
                </GridLayout>
            :
            <>No Symbol Loaded</>}
            

        </>
    )
}

//===========================================================================
//===========================================================================

function DynamicOptionApiGraph({id, formattedOptionChainData, modifyGraphs}) {

    const {chainData, underlying} = formattedOptionChainData;
    const expDates = Object.keys(chainData);
    const strikes = Object.keys(chainData[expDates[0]]);
    const yAxisOptions = Object.keys(chainData[expDates[0]][strikes[0]]["call"]).sort();
    const xScaleOptions = ["Categorical", "Numeric"];
    const yScaleOptions = ['auto', 'linear', 'pow', 'sqrt', 'log', 'identity','time','band','point','ordinal','quantile','quantize','utc','sequential','threshold'];
    const graphDisplayTypeOptions = ["Bar", "Line"];

    const [selectedExpDate, setSelectedExpDate] = useState(expDates[0]);
    const [selectedYAxis, setSelectedYAxis] = useState(yAxisOptions[0]);
    const [selectedGraphType, setSelectedGraphType] = useState(graphDisplayTypeOptions[0]);
    const [selectedXScale, setSelectedXScale] = useState(xScaleOptions[0]);
    const [selectedYScale, setSelectedYScale] = useState(yScaleOptions[0]);

    useEffect(() =>{
        //ensure we update the selectedExpDate from the expDates available (i.e., when the previously loaded symbol was using an expDate that is not available on the new symbol)
        const newExpDate = expDates.includes(selectedExpDate) ? selectedExpDate : expDates[0] // if the expDate exists, keep it the same, otherwise change to first in the list.
        setSelectedExpDate(newExpDate)
    },[expDates])

    let data = getDynamicOptionApiGraphData(chainData, selectedExpDate, selectedYAxis);

    
    const getUnderlyingReferenceLine = () => {
        const value = underlying.symbol +" " + underlying.bid;
        return <ReferenceLine x={parseFloat(underlying.close)} stroke="black" label={{value, angle:90}}/>
    }

    const getChartLines = () => {
        const el = [
            <Area type="monotone" dataKey={"call_"+selectedYAxis} name={"Call "+selectedYAxis} stroke="#06d6a0" fill="#06d6a0" fillOpacity={0.15}/>,
            <Area type="monotone" dataKey={"put_"+selectedYAxis} name={"Put "+selectedYAxis} stroke="#ef476f" fill="#ef476f" fillOpacity={0.15}/>,
            <Bar type="monotone" dataKey={"net_"+selectedYAxis} name={"Net "+selectedYAxis} stroke="495867" fill="#118ab2" fillOpacity={0.1}/>
        ]
        return el;
    }

    const getChartBars = () => {
        const el = [
            <Bar type="monotone" dataKey={"call_"+selectedYAxis} name={"Call "+selectedYAxis} stroke="495867" fill="#06d6a0" fillOpacity={1}/>,
            <Bar type="monotone" dataKey={"put_"+selectedYAxis} name={"Put "+selectedYAxis} stroke="495867" fill="#ef476f" fillOpacity={1}/>,
            <Bar type="monotone" dataKey={"net_"+selectedYAxis} name={"Net "+selectedYAxis} stroke="495867" fill="#118ab2" fillOpacity={1}/>
        ]
        return el;
    }

    const getChart = ()=> {
        return (selectedGraphType=="Bar") ? getChartBars() : getChartLines();
    }

    return (
        <>
            <button className="btn btn-close position-absolute end-0" onClick={()=>modifyGraphs(id, 'remove')}></button>
            <h4>{underlying.description}</h4>
            
            <select id="expDateSelector" value={selectedExpDate} onChange={(e)=>setSelectedExpDate(e.target.value)}>
                {expDates.map((i)=><option key={i}>{i}</option>)}
            </select>

            <select id="yAxisSelector" value={selectedYAxis} onChange={(e)=>setSelectedYAxis(e.target.value)}>
                {yAxisOptions.map((i)=><option key={i}>{i}</option>)}
            </select>

            <select id="graphDisplayTypeSelector" value={selectedGraphType} onChange={(e)=>setSelectedGraphType(e.target.value)}>
                {graphDisplayTypeOptions.map((i)=><option key={i}>{i}</option>)}
            </select>

            <select id="xScaleSelector" value={selectedXScale} onChange={(e)=>setSelectedXScale(e.target.value)}>
                {xScaleOptions.map((i)=><option key={i}>{i}</option>)}
            </select>

            <select id="yScaleSelector" value={selectedYScale} onChange={(e)=>setSelectedYScale(e.target.value)}>
                {yScaleOptions.map((i)=><option key={i}>{i}</option>)}
            </select>

            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }} barGap={0}>
                    <Tooltip />
                    <XAxis  dataKey="strike" type={(selectedXScale=="Categorical")?"category":"number"} domain={['dataMin','dataMax']} />
                    <YAxis scale={selectedYScale}/>
                    {getUnderlyingReferenceLine()}
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
                    {getChart().map(i=>i)}
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}



//===========================================================================
//===========================================================================



// Workshopping abstract graph stuff:

function getDynamicOptionApiGraphData(formattedOptionChainData, expDate, yAxis) {
    // this will return graph data for a given option param across strike prices
    //  Note: for now, this expects data from a single expiration date.
    let graphData = [];
    let strikes = formattedOptionChainData[expDate];
    //console.log("getDynamicOptionApiGraphData > ", formattedOptionChainData)
    for(let s in strikes) {
        let call = strikes[s].call[yAxis];
        let put = strikes[s].put[yAxis];
        let net = call + put;
        graphData.push({'strike': parseFloat(s), ["call_"+yAxis]: call, ["put_"+yAxis]: put, ["net_"+yAxis]: net})
    }
    return graphData;
}



function getChartContentComponent({type, dataKey, name, stroke="black", fill="black", fillOpacity=0 }) {
    switch(type.toUpperCase()) {
        case "AREA": return <Area type="monotone" dataKey={dataKey} name={name} stroke={stroke} fill={fill} fillOpacity={fillOpacity}/>;
        case "BAR": return <Bar type="monotone" dataKey={dataKey} name={name} stroke={stroke} fill={fill} fillOpacity={fillOpacity}/>
        default: <Line type="monotone" dataKey={dataKey} name={name} stroke={stroke} fill={fill} fillOpacity={fillOpacity}/>;
    }
}

function getChartContents({yAxisDataArray}) {
    return yAxisDataArray.map((i)=>getChartContentComponent({...i.config}));
}

function getChartData({yAxisDataArray}) {

}


function getDynamicGraphData_api(dataSource, graphState) {

    const xAxis = graphState.activeAxis.x;
    const yAxis = graphState.activeAxis.y;
    const boundModifier = graphState.graphControlSettings.bounds;
    const dataIncrement = graphState.graphControlSettings.granularity;

    if(dataSource.length == 0) return []; // if no positions, then exit.

    let data = [];
    //Use xaxis to determine the base around which we decide the bounds
    const lowerBound = parseFloat(dataSource.reduce((min, o) => graphState.axisOptions[xAxis](o) < min ? graphState.axisOptions[xAxis](o) : min, graphState.axisOptions[xAxis](dataSource[0])))-parseFloat(boundModifier);
    const upperBound = parseFloat(dataSource.reduce((max, o) => graphState.axisOptions[xAxis](o) < max ? graphState.axisOptions[xAxis](o) : max, graphState.axisOptions[xAxis](dataSource[0])))+parseFloat(boundModifier);
  
    for(let x=lowerBound; x<upperBound; x+=dataIncrement) { //iterate across the xaxis //TODO: account for upperbound being lower than lowerbound
        let cumY = 0;
        for(let pos of dataSource) {
            cumY += graphState.axisOptions[yAxis](pos, {[xAxis]: x});
        }
        data.push({'x': x, 'y': cumY})
    }
    if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
    return data;
}