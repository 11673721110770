//030621
// GridItemComponent
// - a resizable, draggable content container
// - takes props from ContentGrid which tell it which component to render and the props to pass it.
// - has a passThrough func which passes state from it's content component back to the content grid.

import {useState} from 'react';

export function GridItemComponent({id, content, state, gridItemStateArray}) {
    //content should be ContentObj shape: {id,component,props,state}
    const Content = content.component; // need to assign to variable(which must begin upperCase) in order to use as a react component in jsx.

    function setContentState(newContentState) {
        let newState = {...state}
        newState.content.state=newContentState;
        gridItemStateArray.setItem(newState);
    }
    
    return (<>
        <button className="btn btn-close preventDrag" onClick={()=>gridItemStateArray.removeById(id)}></button>
        <Content {...content.props} {...content} setState={setContentState} gridItem={state} gridItemStateArray={gridItemStateArray}/>
    </>)
}