//030621
// Content Grid
// - a grid space to display content containers
// - handles ability to add and remove gridItems
// - manages grid layout
// - has a generic state array, gridItems, which contains objs, {id,component,props}
// - renders GridItemComponents on the grid and passes props

import {useState} from 'react';
import GridLayout from 'react-grid-layout';
import {GridItemComponent} from './GridItemComponent';
import {StateArray} from '../state arrays/StateArrays';

export function ContentGrid({stateArray, initGridItems=[], gridLayoutProps={}, gridConfig={}}) {

    const gridItemStateArray = (stateArray==undefined) ? new StateArray({initItems:initGridItems}) : stateArray;

    gridConfig = {cols:20, rowHeight:100, width:1800, ...gridConfig} //default gridConfig
    gridLayoutProps={                                               //default gridLayoutProps
        onLayoutChange:(layout)=>{updateGridItemsConfig(layout)},
        verticalCompact:true,
        compactType:'horizontal',
        draggableCancel:'.preventDrag',
        ...gridLayoutProps
    }

    function updateGridItemsConfig(newItemsConfig) {
        // updates the statearray when layout changes are made
        let batch = {}
        newItemsConfig.map(i=>batch[i.i]={gridItemConfig: i})
        gridItemStateArray.setPropertyByBatch(batch)
    }
    

    return (<>
        <button className="btn btn-primary" onClick={()=>gridItemStateArray.addItem()}>+</button>
        <GridLayout className="layout" {...gridConfig} {...gridLayoutProps}>
            {gridItemStateArray.items.map((i)=>{
                return (
                    <div key={i.id} data-grid={i.gridItemConfig}>
                        <GridItemComponent  id={i.id} content={i.content} state={i} gridItemStateArray={gridItemStateArray}/>
                    </div>
                )
            })}
        </GridLayout>
    </>)

}