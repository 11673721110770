//021321
// Quick graph to display rate of change of value over time graph

import { useState } from 'react';
import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ComposedChart } from 'recharts';
import GraphControlsUI from './GraphControlsUI'
import GraphDataSelector from './GraphDataSelectors'
import {getROC} from '../../util/dataUtil'


function getValueOverTimeData(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10) {
  // takes array of objects that include {strike, netPremium, optionType, optionDirection}
  if(optionsData.length==0) return [];
  const data = []
  const lowerBound = 0;
  const upperBound = parseFloat(optionsData.reduce((max, o) => o.daysToExpiration > max ? o.daysToExpiration : max, optionsData[0].daysToExpiration));

  for(var i=upperBound + parseFloat(relUpperBound); i>=lowerBound; i-=dataIncrements) {

      var cumPremium = 0;
      var cumIntrinsic = 0;
      var cumExtrinsic = 0;
      for(let opt of optionsData) {
        cumPremium += opt.getPremium({daysToExpiration: i});
        cumIntrinsic += opt.getIntrinsicValue({daysToExpiration: i});
        cumExtrinsic += opt.getExtrinsicValue({daysToExpiration: i});
      }
      var intrinsicRng = cumIntrinsic>=0 ? [0, cumIntrinsic] : [];
      var extrinsicRng = [cumIntrinsic, cumPremium]
      var extrinsicAbsoluteRng = [0, cumExtrinsic]
      data.push({dte: i, cumPremium, intrinsicRng, extrinsicRng, extrinsicAbsoluteRng, cumExtrinsic});
  }
  return data;
}




function ValueOverTimeROCGraph({optionPositions}) {
  console.log("roc: ",getROC);
    const [graphControlSettings,setGraphControlSettings] = useState({bounds: 15, granularity: 1});
    const valueData = getValueOverTimeData(optionPositions, graphControlSettings.granularity, graphControlSettings.bounds, graphControlSettings.bounds);
    
    console.log("roc: ",getROC);
    let data = getROC(valueData,"cumExtrinsic");
    data = getROC(data, "cumExtrinsic_ROC")


  return (
    <div className="row">
      {/*JSON.stringify(optionPositions)*/}
      <div className="section col-md-10">
        <h3 className="section-title">ROC of Extrinsic Value over time until Expiration:</h3>
        <div className="section-content">

          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              {/* <Area type="monotone" dataKey="extrinsicRng" name="Extrinsic Value" stroke="#107dac" fill="#107dac" fillOpacity={0.25}/>
              <Area type="monotone" dataKey="intrinsicRng" name="Intrinsic Value" stroke="#5cb85c" fill="#5cb85c" fillOpacity={0.25}/>
              <Line type="monotone" dataKey="cumPremium" name="Value at DTE" stroke="#FB8833" strokeWidth={2} dot={false}/> */}
              <Area type="monotone" dataKey="cumExtrinsic_ROC" name="ROC(Extrinsic Value at DTE)" stroke="#107dac" fill="none" fillOpacity={0.25}/>
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="dte" name="DTE" label={()=>"DTE"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              {/* <Area type="monotone" dataKey="extrinsicRng" name="Extrinsic Value" stroke="#107dac" fill="#107dac" fillOpacity={0.25}/>
              <Area type="monotone" dataKey="intrinsicRng" name="Intrinsic Value" stroke="#5cb85c" fill="#5cb85c" fillOpacity={0.25}/>
              <Line type="monotone" dataKey="cumPremium" name="Value at DTE" stroke="#FB8833" strokeWidth={2} dot={false}/> */}
              <Area type="monotone" dataKey="cumExtrinsic_ROC_ROC" name="ROC(ROC(Extrinsic Value at DTE))" stroke="#107dac" fill="none" fillOpacity={0.25}/>
            </ComposedChart>
          </ResponsiveContainer>

          {/* <GraphDataSelector data={[{id: 'test1', name:'Test 1'}, {id:'test2',name:'Test 2'}]}/> */}

          <GraphControlsUI graphControlSettings={graphControlSettings} settingsModifiedCallback={setGraphControlSettings}/>
        </div>
      </div>
    </div>
  )
}

export default ValueOverTimeROCGraph;