//021821
// Module to contain info for data generation, manipulation


import { ResponsiveContainer, Cell, Area, ComposedChart,ReferenceLine, LineChart, Label, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Text } from 'recharts';
import TradingViewWidget from 'react-tradingview-widget'
import Plotly from 'plotly.js-gl3d-dist'
import createPlotlyComponent from 'react-plotly.js/factory'



const Plot = createPlotlyComponent(Plotly);


export function getDummyData(nDataPoints) {
    // returns array of dicts for each datapoint: [{x: 0, y: aNumber}, {x: 1, y: anotherNumber}, etc. ]
    let arr = [];
    let y = 0;
    for(let i=0; i<nDataPoints; i++) {
        let yMod = Math.random();
        if(Math.random()>0.5) yMod = -yMod;
        y += yMod;
        arr.push({x: i, y: y});
    }
    console.log(arr);
    return arr;
}

export function getROC(data, seriesName) {
    // returns rate of change of series in array
    data.map((curr,index)=>{
        if(index==0) {return}
        let prev = data[index-1][seriesName];
        let diff = curr[seriesName] - prev;
        curr[seriesName+"_ROC"] = diff;
    })
    return data;
}   

  //=========================  GRAPH DATA RETURNING FUNCTIONS ===================================

export function getDynamicGraphData3d(positionsArray, graphState) {

    const xAxis = graphState.activeAxis.x;
    const yAxis = graphState.activeAxis.y;
    const zAxis = graphState.activeAxis.z;
    const boundModifier = graphState.graphControlSettings.bounds;
    const dataIncrement = graphState.graphControlSettings.granularity;

    if(positionsArray.length == 0) return []; // if no positions, then exit.

    let data = [];
    //Use xaxis to determine the base around which we decide the bounds
    const xLowerBound = parseFloat(positionsArray.reduce((min, o) => graphState.axisOptions[xAxis](o) < min ? graphState.axisOptions[xAxis](o) : min, graphState.axisOptions[xAxis](positionsArray[0])))-parseFloat(boundModifier);
    const xUpperBound = parseFloat(positionsArray.reduce((max, o) => graphState.axisOptions[xAxis](o) < max ? graphState.axisOptions[xAxis](o) : max, graphState.axisOptions[xAxis](positionsArray[0])))+parseFloat(boundModifier);
  
    const yLowerBound = parseFloat(positionsArray.reduce((min, o) => graphState.axisOptions[yAxis](o) < min ? graphState.axisOptions[yAxis](o) : min, graphState.axisOptions[yAxis](positionsArray[0])))-parseFloat(boundModifier);
    const yUpperBound = parseFloat(positionsArray.reduce((max, o) => graphState.axisOptions[yAxis](o) < max ? graphState.axisOptions[yAxis](o) : max, graphState.axisOptions[yAxis](positionsArray[0])))+parseFloat(boundModifier);
  

    for(let x=xLowerBound; x<xUpperBound; x+=dataIncrement) { //iterate across the xaxis //TODO: account for upperbound being lower than lowerbound

        for(let y=yLowerBound; y<yUpperBound; y+=dataIncrement)
        {
          if(zAxis!=null) {
            let cumZ = 0;
            for(let pos of positionsArray) {
              cumZ += graphState.axisOptions[zAxis](pos, {[xAxis]: x, [yAxis]: y});
            }
            data.push({x,y,z:cumZ});
          }
        }
    }
    if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
    return data;
}

export function getDynamicGraphData2d(positionsArray, graphState) { //bak

  const perf_t0 = performance.now();

  const xAxis = graphState.activeAxis.x;
  const yAxis = graphState.activeAxis.y;
  const boundModifier = graphState.graphControlSettings.bounds;
  const dataIncrement = graphState.graphControlSettings.granularity;

  if(positionsArray.length == 0) return []; // if no positions, then exit.

  let data = [];
  //Use xaxis to determine the base around which we decide the bounds
  const lowerBound = parseFloat(positionsArray.reduce((min, o) => graphState.axisOptions[xAxis](o) < min ? graphState.axisOptions[xAxis](o) : min, graphState.axisOptions[xAxis](positionsArray[0])))-parseFloat(boundModifier);
  const upperBound = parseFloat(positionsArray.reduce((max, o) => graphState.axisOptions[xAxis](o) < max ? graphState.axisOptions[xAxis](o) : max, graphState.axisOptions[xAxis](positionsArray[0])))+parseFloat(boundModifier);

  for(let x=lowerBound; x<upperBound; x+=dataIncrement) { //iterate across the xaxis //TODO: account for upperbound being lower than lowerbound
      let cumY = 0;
      for(let pos of positionsArray) {
          cumY += graphState.axisOptions[yAxis](pos, {[xAxis]: x});
      }
      data.push({'x': x, 'y': cumY})
  }
  if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
  const perf_t1 = performance.now();
  console.log("Performance > Custom > ", perf_t1-perf_t0);
  return data;
}



// export function getDynamicGraphData3D(positionsArray, graphState) {

//   const xAxis = graphState.activeAxis.x;
//   const yAxis = graphState.activeAxis.y;
//   const zAxis = graphState.activeAxis.z;
//   const boundModifier = graphState.graphControlSettings.bounds;
//   const dataIncrement = graphState.graphControlSettings.granularity;

//   if(positionsArray.length == 0) return []; // if no positions, then exit.

//   let data = [];
//   //Use xaxis to determine the base around which we decide the bounds
//   const lowerBound = parseFloat(positionsArray.reduce((min, o) => graphState.axisOptions[xAxis](o) < min ? graphState.axisOptions[xAxis](o) : min, graphState.axisOptions[xAxis](positionsArray[0])))-parseFloat(boundModifier);
//   const upperBound = parseFloat(positionsArray.reduce((max, o) => graphState.axisOptions[xAxis](o) < max ? graphState.axisOptions[xAxis](o) : max, graphState.axisOptions[xAxis](positionsArray[0])))+parseFloat(boundModifier);

//   for(let x=lowerBound; x<upperBound; x+=dataIncrement) { //iterate across the xaxis //TODO: account for upperbound being lower than lowerbound
//       let cumY = 0;
//       for(let pos of positionsArray) {
//           cumY += graphState.axisOptions[yAxis](pos, {[xAxis]: x});
//       }
//       data.push({'x': x, 'y': cumY})
//   }
//   if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
//   return data;
// }

export function getPLData(positionsArray, graphState) {

    const boundModifier = graphState.graphControlSettings.bounds;
    const dataIncrement = graphState.graphControlSettings.granularity;

    // takes array of objects that include {strike, netPremium, optionType, optionDirection}
    if(positionsArray.length==0) return [];
    let data = []
    const lowerBound = parseFloat(positionsArray.reduce((min, o) => o.strike < min ? o.strike : min, positionsArray[0].strike)) - parseFloat(boundModifier);
    const upperBound = parseFloat(positionsArray.reduce((max, o) => o.strike > max ? o.strike : max, positionsArray[0].strike)) + parseFloat(boundModifier);
  
    const shortestDte = positionsArray.reduce((min, o) => o.daysToExpiration < min ? o.daysToExpiration : min, positionsArray[0].daysToExpiration)

    for(var i=lowerBound; i<upperBound; i+=dataIncrement) {
        var cumPl = 0;
        for(let opt of positionsArray) {
            cumPl += opt.daysToExpiration===shortestDte ? opt.getPLAtExpiration({underlyingPrice:i}) : opt.getPL({daysToExpiration: opt.daysToExpiration-shortestDte,underlyingPrice:i}); //
        }
        var profitRng = cumPl>=0 ? [0,cumPl] : []
        var lossRng = cumPl<=0 ? [cumPl, 0] : []
        data.push({underlyingPriceAtExpiry: i, pl: cumPl, profitRng, lossRng})
    }
    if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
    return data;
  }

export function getCompositeValueData(positionsArray, graphState) {

    const boundModifier = parseFloat(graphState.graphControlSettings.bounds);
    const dataIncrement = parseFloat(graphState.graphControlSettings.granularity);

    if(positionsArray.length==0) return [];
    let data = []
    const lowerBound = parseFloat(positionsArray.reduce((min, o) => o.underlyingPrice < min ? o.underlyingPrice : min, positionsArray[0].underlyingPrice)) - boundModifier;
    const upperBound = parseFloat(positionsArray.reduce((max, o) => o.underlyingPrice > max ? o.underlyingPrice : max, positionsArray[0].underlyingPrice)) + boundModifier;
  
    for(var i=lowerBound; i<upperBound; i+=dataIncrement) {
  
        var cumPremium = 0;
        var cumIntrinsic = 0;
        var cumExtrinsic = 0;
        for(let opt of positionsArray) {
          cumPremium += opt.getPremium({strike: i});
          cumIntrinsic += opt.getIntrinsicValue({strike: i});
          cumExtrinsic += opt.getExtrinsicValue({strike: i});
        }
        var intrinsicRng = cumIntrinsic>=0 ? [0, cumIntrinsic] : [];
        var extrinsicRng = [cumIntrinsic, cumPremium]
        data.push({strike: i, cumPremium, intrinsicRng, extrinsicRng, cumExtrinsic});
    }
    if(graphState.graphControlSettings.reverseData==true) data=data.reverse();
    return data;
  }

  //=========================  CHART RETURNING FUNCTIONS ===================================

  export function getPLGraph(graphState) {
      return (
        <ComposedChart data={graphState.data} margin={{ top: 5, right: 25, bottom: 5, left: -5 }}>
          <Tooltip  formatter={rangeDataTooltipFormatter} labelFormatter={(value)=>"UnderlyingPrice At Expiry: "+value} />
          <XAxis dataKey="underlyingPriceAtExpiry"/>
          <YAxis />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Legend verticalAlign="bottom" height={0}/>
          <ReferenceLine y={0} stroke="green" strokeDasharray="3 3" />              
          <Line type="monotone" dataKey="pl" name="P/L At Expiry" stroke="#428bca" strokeWidth={2} dot={false} />
          <Area type="monotone" dataKey="profitRng" name="Profit" stroke="none" fill="#5cb85c" fillOpacity={0.25} />
          <Area type="monotone" dataKey="lossRng" name="Loss" stroke="none" fill="#d9534f" fillOpacity={0.25} />
        </ComposedChart>
        )
  }

export function getDynamicGraph(graphState) {
    return (
        <ComposedChart data={graphState.data} margin={{ top: 5, right: 25, bottom: 5, left: -5 }}>
        <Tooltip formatter={fixedValueTooltipFormatter} labelFormatter={(value)=>graphState.activeAxis.x+": "+value}/>
        <XAxis dataKey="x" name={graphState.activeAxis.x}/>
        <YAxis />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Legend verticalAlign="bottom" height={0} margin={{top:0}}/>
        <Area type="monotone" dataKey="y" name={graphState.activeAxis.y} stroke="#107dac" fill="#107dac" fillOpacity={0.05}/>
        </ComposedChart>
    )
}

export function getCompositeValueGraph(graphState) {
    return (
        <ComposedChart data={graphState.data} margin={{ top: 5, right: 25, bottom: 5, left: -5 }}>
        <Tooltip formatter={rangeDataTooltipFormatter} labelFormatter={(value)=>"Strike: "+value} />
        <XAxis dataKey="strike" name="Strike" label={(v)=>"Strike"}/>
        <YAxis />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
        <Area type="monotone" dataKey="extrinsicRng" name="Extrinsic Value" stroke="#107dac" fill="#107dac" fillOpacity={0.25}/>
        <Area type="monotone" dataKey="intrinsicRng" name="Intrinsic Value" stroke="#5cb85c" fill="#5cb85c" fillOpacity={0.25}/>
        <Line type="monotone" dataKey="cumPremium" name="Option Value At Strike" stroke="#FB8833" strokeWidth={2} dot={false}/>
      </ComposedChart>
    )
}


export function getPlProbabilityGraph(graphState) {
  const getColor=(pl)=> pl<0 ? "#d9534f" : "#5cb85c"
  const {ev, probPos,probNeg,totalProb} = graphState.data[0]
  return (
    <BarChart data={graphState.data} margin={{bottom:10}} >
      
      <Tooltip formatter={fixedValueTooltipFormatter} labelFormatter={(value)=>"P/L: $"+value} />
      <CartesianGrid strokeDasharray="5 5" />
      <XAxis dataKey="pl" >
      <Label position='bottom' offset={-2}>{"EV: "+ev+" │ P(+): "+probPos+" │ P(-): "+probNeg }</Label>
      </XAxis>
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      <Bar name='Probability' dataKey='probability' fillOpacity={0.8}>
        {graphState.data.map((dataPoint) => (
          <Cell fill={getColor(dataPoint.pl)} />
        ))}
        </Bar>
  </BarChart>

  )
}


export function getTradingViewWidget(graphState) {
  
  return <TradingViewWidget {...graphState.data} autosize /> 
}


  //=========================  TEST CHARTS  ===================================


// export function getTradingViewChart_raw(graphState) {
  
//   //const data = {data: graphState.data};
//   const chart = createChart(document.getElementById(graphState.id), { width: 400, height: 300 });
//   const lineSeries = chart.addLineSeries();
//   lineSeries.setData([
//       { time: '2019-04-11', value: 80.01 },
//       { time: '2019-04-12', value: 96.63 },
//       { time: '2019-04-13', value: 76.64 },
//       { time: '2019-04-14', value: 81.89 },
//       { time: '2019-04-15', value: 74.43 },
//       { time: '2019-04-16', value: 80.01 },
//       { time: '2019-04-17', value: 96.63 },
//       { time: '2019-04-18', value: 76.64 },
//       { time: '2019-04-19', value: 81.89 },
//       { time: '2019-04-20', value: 74.43 },
//   ]);
//   return (
//     <></>
//   )
// }

// export function getTradingViewChartWrapper(graphState) {
  
//   //const data = {data: graphState.data};
//     const initOptions = {
//       alignLabels: true,
//       timeScale: {
//           rightOffset: 12,
//           barSpacing: 3,
//           fixLeftEdge: true,
//           lockVisibleTimeRangeOnResize: true,
//           rightBarStaysOnScroll: true,
//           borderVisible: false,
//           borderColor: "#fff000",
//           visible: true,
//           timeVisible: true,
//           secondsVisible: false
//       }
//   };

//   const initData = [{ data: [  
//       { time: '2018-10-19', open: 180.34, high: 180.99, low: 178.57, close: 179.85 },
//       { time: '2018-10-22', open: 180.82, high: 181.40, low: 177.56, close: 178.75 },
//       { time: '2018-10-23', open: 175.77, high: 179.49, low: 175.44, close: 178.53 },
//       { time: '2018-10-24', open: 178.58, high: 182.37, low: 176.31, close: 176.97 },
//       { time: '2018-10-25', open: 177.52, high: 180.50, low: 176.83, close: 179.07 },
//       { time: '2018-10-26', open: 176.88, high: 177.34, low: 170.91, close: 172.23 },
//       { time: '2018-10-29', open: 173.74, high: 175.99, low: 170.95, close: 173.20 },
//       { time: '2018-10-30', open: 173.16, high: 176.43, low: 172.64, close: 176.24 },
//       { time: '2018-10-31', open: 177.98, high: 178.85, low: 175.59, close: 175.88 },
//       { time: '2018-11-01', open: 176.84, high: 180.86, low: 175.90, close: 180.46 },
//       { time: '2018-11-02', open: 182.47, high: 183.01, low: 177.39, close: 179.93 },
//       { time: '2018-11-05', open: 181.02, high: 182.41, low: 179.30, close: 182.19 }
//   ]}];
//   return (
//     <Chart options={initOptions} candlestickSeries={initData} autoWidth height={320}/>
//   )
// }


// export function reactPlotlyTest(graphState) {
//   return (
//     <Plot
//       data={[
//         {
//           x: [1, 2, 3],
//           y: [2, 6, 3],
//           type: 'scatter',
//           mode: 'lines+markers',
//           marker: {color: 'red'},
//         },
//         {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
//       ]}
//       layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
//     />
//   );
// }



function getrandom(num , mul) {
  // needed for 3d chart exapmle
      var value = [ ];
      for(let i=0;i<=num;i++) {
          var rand = Math.random() * mul;
          value.push(rand);
      }
      return value;
  }

function plotlyScatter3dTestData(positionsArray,graphState) {
  return [
    {
      opacity:0.5,
      type: 'scatter3d',
      x: [1],
      y: [2],
      z: [3],
     },
    // {
    //  opacity:0.4,
    //  type: 'scatter3d',
    //  x: getrandom(50 , -75),
    //  y: getrandom(50 , -75),
    //  z: getrandom(50 , -75),
    // },
    // {
    //  opacity:0.5,
    //  type: 'scatter3d',
    //  x: getrandom(50 , -75),
    //  y: getrandom(50 , 75),
    //  z: getrandom(50 , 75),
    // },
    //   {
    //  opacity:0.5,
    //  type: 'scatter3d',
    //  x: getrandom(50 , 100),
    //  y: getrandom(50 , 100),
    //  z: getrandom(50 , 100),
    // }
  ]
}


function plotlyScatter3dData(posistionsArray, graphState) {
  // formats data for 3d plotly plot
  const data = getDynamicGraphData3d(posistionsArray,graphState);
  const x = data.map(i=>i.x)
  const y = data.map(i=>i.y)
  const z = data.map(i=>i.z)

  const hovertemplate = graphState.activeAxis.x+": %{x}<br />"+graphState.activeAxis.y+": %{y}<br />"+graphState.activeAxis.z+": %{z}"+"<extra></extra>";
  const plotlySettings = {opacity:1, type: 'scatter3d', mode:'markers', marker: {color: z, colorscale: 'Viridis'}, hovertemplate }
  
  return [{x,y,z, ...plotlySettings}];
}

function plotlyScatter3d(graphState) {

  // let width=graphState.gridLayout.w*(graphState.gridState.width/graphState.gridState.nCols);
  // width-=width/10;
  // let height=(graphState.gridLayout.h*graphState.gridState.rowHeight)
  // height-=height/5

  const layout = {
    scene:{
       aspectmode: "cube",
    //  aspectratio: {
    //    x: 1, y: 0.7, z: 1,
    //   },
     xaxis: {
      nticks: 10,
      title: graphState.activeAxis.x
    },
     yaxis: {
      nticks: 10,
      title: graphState.activeAxis.y
    },
     zaxis: {
     nticks: 10,
     title: graphState.activeAxis.z
    }},
    autosize:true,
    //width: width,
    //height: height,
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    }
  };


  const config = {displayModeBar:false, responsive:true, scrollZoom:true} //, modeBarButtonsToRemove: ['toImage', 'zoom3d','pan3d','orbitRotation,', 'tableRotation', 'handleDrag3d','resetCammeraLastSave3d','hoverClosest3d']} //todo: this wasn't showing up when displaymodebar= true after correcting the position within the modular component. come back.
  //console.log("plotlyScatter3d rendered", graphState)
  return (
    <>
    <Plot className="preventDrag d-flex w-100 h-100" data={graphState.data} layout={layout} config={config}/>
    </>
  )
}


function plotlySurface3dData(posistionsArray, graphState) {
  // formats data for 3d plotly plot
  const data = getDynamicGraphData3d(posistionsArray,graphState);
  const x = data.map(i=>i.x)
  const y = data.map(i=>i.y)
  const z = data.map(i=>i.z)
  return [{x,y,z, opacity:0.5, type: 'surface'}];
}

function plotlySurface3d(graphState) {

  const layout = {
    title: 'Surface3D (Under Construction)',
    autosize: false,
    width: 500,
    height: 500,
    margin: {
      l: 65,
      r: 50,
      b: 65,
      t: 90,
    }
  };

  return (
    <>
    <Plot className="preventDrag" data={graphState.data} layout={layout}/>
    </>
  )
}


function plProbabilityData(positionsArray, graphState) {
  /**040722 */

  //mvp 1:
  // - Break down PL into ranges.
  //    - iterate through PL at underlyingPriceAtExpiry.
  //    - if PL == previous PL, update upperbound on current plRange array.
  //        else, add a new item to plRanges array with underylyingPrice as lowerbound and upper bound and PL value
  // - use delta (prob itm) at the highest end of range (or on range bounds) to estimate probability of falling into a particular PL range
  //    - iterate through plRanges
  //      - get delta at a strike == underlyingPriceRngStart and Stop 
  //    - 

  /**
   * TODO:
   *  - see todo notes in function below
   *  - truncate decimal places in pl values
   *  - consider using continuous pl values and display prob over those
   *  - consider adding probability direcly onto PL chart as text and range. e.g. |________40%________|
   *    - could segment probabilities breakeven to break even? 
   *       - Or in constant to breakeven, and constant to changing and changing to breakeven. 
   *      
   *    - potentially option to allow user to highlight sections of the PL graph to determine probability of range?
   */


  const plData = getPLData(positionsArray,graphState).map((v)=>({...v,pl:parseFloat(v.pl).toFixed(2)}))
  if(plData.length===0) return [{ev: '?', probPos: '?', probNeg:'?'}] //return empty including metadata at first index.
  // console.log({positionsArray, graphState})

  const plRanges = [] //[{pl:plData[0].pl,underlyingPriceRngStart:plData[0].underlyingPriceAtExpiry, underlyingPriceRngStop:plData[0].underlyingPriceAtExpiry}] // takes {underlyingPriceRngStart, underlyingPriceRangeStop, pl}
  //todo: trim pl values to avoid floatingpoint errors when comparing below
  let lastPlRange = {pl:'init'}
  for(let i of plData) {
    if(i.pl===lastPlRange.pl) {
      lastPlRange.underlyingPriceRngStop = i.underlyingPriceAtExpiry
    } else {
      plRanges.push({pl:i.pl,underlyingPriceRngStart:i.underlyingPriceAtExpiry, underlyingPriceRngStop:i.underlyingPriceAtExpiry})
      lastPlRange = plRanges[plRanges.length - 1]
    }
  }

  let testOption = {daysToExpiration:Number.MAX_SAFE_INTEGER} //todo: going to use the smallest DTE in the spread for the delta calculations; fix method to generate better probability that accounts for all contracts in the spread
  positionsArray.map((o)=>{if(o.daysToExpiration<testOption.daysToExpiration) testOption=o})

  // Add probabilities
  for(let i of plRanges) {
    let rngStart = i.underlyingPriceRngStart
    let rngStop = i.underlyingPriceRngStop
    if(rngStart===rngStop){rngStart-=1; rngStop+=1}
    const deltaRngStart = testOption.getProbITM({strike:rngStart})
    const deltaRngStop = testOption.getProbITM({strike:rngStop})
    const probInRng = Math.abs(deltaRngStop-deltaRngStart);
    i['probability'] = probInRng;
  }


  //Consolidate same PLs
  let consolidatedPlProbability = []
  for(let i of plRanges) {
    let plInData = false
    for(let ii of consolidatedPlProbability) {
      if(i.pl===(ii.pl)) plInData=true;
    }
    if(plInData) continue;
    const totalProb = plRanges.reduce((totalProb,v)=>i.pl===v.pl ? totalProb+v.probability : totalProb,0)
    consolidatedPlProbability.push({pl:i.pl, probability:totalProb})
  }

  const ev = consolidatedPlProbability.reduce((ev,i)=>ev+=i.probability>0?parseFloat(i.pl*i.probability):0,0).toFixed(2)
  const probPos = consolidatedPlProbability.reduce((probPos,i)=>probPos+=parseFloat(i.pl>=0?i.probability:0),0).toFixed(2)
  const probNeg = consolidatedPlProbability.reduce((probNeg,i)=>probNeg+=parseFloat(i.pl<0?i.probability:0),0).toFixed(2)
  // const totalProb = consolidatedPlProbability.reduce((totalProb,i)=>totalProb+=i.probability,0)
  const metaData = {ev, probPos,probNeg}
  consolidatedPlProbability[0] = {...consolidatedPlProbability[0], ...metaData}; //add metaData to first datapoint

  // console.log('dataUtil.getPlProbabilityData() > ',{data})

  // console.log('PL RANGES',{plRanges})
  console.log({metaData})
  return consolidatedPlProbability

}

function tradingViewWidgetData(positionsArray, graphState) {
  const symbol = graphState.data.symbol ? graphState.data.symbol : "ES1!";
  return {symbol}

}



  //=========================  CHART FORMATTING FUNCTIONS ===================================

  function rangeDataTooltipFormatter(value, name, props) {
    // formats range data for graphs to show their difference instead of the range in a graph's tooltip (when you hover over it)
    if(value.length==0) return 0;
    if(typeof(value)!=Number && value.length>1) value = value[1]-value[0];
    value = parseFloat(value).toFixed(2)
    return [value, name];
}

function fixedValueTooltipFormatter(value, name, props) {
  // formats tooltip data to fixed value
  //value = parseFloat(value).toFixed(2)
  let nDecimals = 2;
  if(value>1 || value<-1) {nDecimals=2}
  else nDecimals=6;
  value = parseFloat(value).toFixed(nDecimals)
  return [value, name];
}

/*
  022821 temp brainstorm

  for each datatype that goes into a chart, user can set display type (bar,line, etc) and other features like color, etc.
*/

  //=========================  FINAL EXPORT  ===================================

  const graphTypes = {
        pl: {id: 'pl', name: "Profit/Loss", getChart: getPLGraph, getData:getPLData}, 
        compositeValue: {id: 'compositeValue' ,name: "Composite Value", getChart: getCompositeValueGraph, getData: getCompositeValueData}, 
        plProbability: {id: 'plProbability' ,name: "P/L Probability", getChart: getPlProbabilityGraph, getData: plProbabilityData}, 
        custom: {id: 'custom', name: "custom", getChart: getDynamicGraph, getData: getDynamicGraphData2d},
        //custom_seriesApproach: {id: 'custom_seriesApproach', name: "custom_seriesApproach", getChart: getDynamicGraph, getData: getDynamicGraphData2d_seriesApproach},
        //custom_indexReducer: {id: 'custom_indexReducer', name: "custom_indexReducer", getChart: getDynamicGraph, getData: getDynamicGraphData2d_indexApproachWithReducer},
        //custom_newAxisOpts: {id: 'custom_newAxisOpts', name: "custom_newAxisOpts", getChart: getDynamicGraph, getData: getDynamicGraphData2d_testWithNewAxisOpts},
        //custom_generalAxisOpts: {id: 'custom_generalAxisOpts', name: "custom_generalAxisOpts", getChart: getDynamicGraph, getData: getDynamicGraphData2d_testWithGeneralAxisOpts},
        market: {id: 'market', name: "Market", getChart: getTradingViewWidget, getData:tradingViewWidgetData},
        //tvTest: {id: 'tvTest', name: "TradingView", getChart: getTradingViewChartWrapper, getData:(args)=>[]}
        scatter3d: {id: 'scatter3d', name: "Scatter3D", getChart: plotlyScatter3d, getData:plotlyScatter3dData},
        surface3d: {id: 'surface3d', name: "Surface3D", getChart: plotlySurface3d, getData:plotlySurface3dData}
    };

  export default graphTypes;
  