/**
 * 103021
 * OptionChain.jsx
 * 
 * Live Option Chain UI 
 */

/**Todo 103021
 * - create div container
 * - create vertical div to use as center and for spacing reference
 *      - don't need a vertical div.. just use an anchor point with set call/put obj sizes..?
 * - create put/call button ui
 * - create func that places put/call button ui on vertical div
 * - create func that translates prices to y coord on vertical div
 *      - ie. set spacing size unit, like 5 pixels per dollar, or 30 pixels between strikes.
 *          then, place strikes +/- around the current price
 */

/**Todo 103121
 * 
 * 1. Hookup live chain to dynamic study @DONE
 *      - user clicks on contractTab (RMB buy LMB sell)
 *      - contract data is used to populate and option position obj and is stored in a multiposition obj
 *      - duplicate dynamic study and provide the live multiposition obj for the chart data source
 * 
 * 3. THEN, focus on improving visuals for chain UI
 *      - build out contract tab
 *      - increase strike range
 *      - set up scrolling expirations
 *      - set up extending chains?
 * 
 */

import './optionchain.css'
import * as tda from '../../api wrappers/tdameritrade/tdameritrade'
import OptionPosition from '../../classes/Option';
import LiveOptionPosition from '../../classes/LiveOption'
import {useState, useEffect} from 'react';
import graphTypes from '../../util/dataUtil';



function getLiveOptionPosition(tdaOptionData, tdaUnderlyingData, buySell="buy") {
    // takes tda option position and returns OptionPosition obj

    //todo: Finish subclassing OptionPosition with LiveOptionPosition and implement
    // let underlyingPrice = tdaUnderlyingData.bid;
    // let callPut=tdaOptionData.putCall.toLowerCase()
    // let strike=tdaOptionData.strikePrice;
    // let dte = tdaOptionData.daysToExpiration;
    // let vol = tdaOptionData.volatility/100
    // let riskFreeRate = 0.01 //todo: pull from 10 yr note
    //return new OptionPosition(underlyingPrice,buySell, callPut,strike,dte,vol,riskFreeRate);
    return new LiveOptionPosition(tdaOptionData,tdaUnderlyingData,buySell);

}

function getLiveOptionPositionList(positionList) {
    //positionList: [{contractData,size,underlyingData}, ...,]
    console.log("getLiveOptionPositionList()>positionList In",positionList)
    let positions = [];
    for (let i of positionList) {
        let buySell= (i.size>0) ? "buy" : "sell"
        for(let ii=0; ii<Math.abs(i.size);ii++) {
            positions.push(getLiveOptionPosition(i.contractData, i.underlyingData, buySell));
        }
    }
    console.log("getLiveOptionPositionList()>positions Out",positions);
    return positions;
}

function ContractTabFC(props) {

    //todo: consider making size attribute a state.. though if you are still sending updates up to a parent component, this will cause double rendering
    //todo: add shift key + click to multiply positionsize increment by 10

    let contractData = props.contractData;
    let contractType = props.contractData.putCall;
    let isCall = contractType=="CALL"
    let id = props.contractData.symbol;
    //let size = 0; //0 = neutral, neg #s are short contracts, pos are long
    let size = (id in props.positions) ? props.positions[id].size : 0;
    //let isSelected = size!==0;

    let strike = (parseInt(props.strike.split('.')[1])>0) ? props.strike : props.strike.split('.')[0]

    let onRightClick=(e)=>{
        e.preventDefault();
        props.updatePositionCB(id, contractData, -1);
    }

    let onLeftClick = (e) => {
        props.updatePositionCB(id, contractData, 1);
    }

    let sizeStr = (size>0) ? "+".concat(size) : size
    let classNames="contractTabBtn flex-nowrap btn ".concat((isCall) ? "btn-success" : "btn-danger") //make sure you have a space at the end of the first string

    return <div className="contractTabContainer col p-0 " id="contractTabContainer">
        {/* {size!==0 && isCall? <div className="col positionSize">{sizeStr}</div> : null} */}
        <div className={classNames} onClick={onLeftClick} onContextMenu={onRightClick} id='contractTabBtn' > 
            <div className="row flex-nowrap h-25 " id="contractTab-topRow">
                {/* <div className="col-9"></div> <div className="col-1 contractTab-positionSize">{sizeStr}</div> */}
                <div className="contractTab-positionSize flexCentered">{size!==0?sizeStr:null}</div>
            </div>
            <div className="row flex-nowrap h-50 " id="contractTab-midRow">
                <div className="contractTab-strike flexCentered p-0">{strike}</div>
            </div>
            <div className="row flex-nowrap h-25" id="contractTab-botRow">
                <div className="col flexCentered"></div>
            </div>
        </div>
        {/* {size!==0 && !isCall? <div className="col positionSize">{sizeStr}</div> : null} */}
    </div>
}

function ExpDateChainFC(props) {
    //expects props.expDate props.chain = []

    let dateStr = props.expDate //todo: format date

    return <div className="expDate-chain col">
        <div className="expDate row flexCentered">{dateStr}</div>
        <div className="chain-container col flex-nowrap">
            {/* <div className="col"> */}
                {Object.keys(props.chain).map((s)=>{
                   return  (<div className='row flex-nowrap '>
                        <ContractTabFC key={s+'call'} strike={s} contractData={props.chain[s]['call']} updatePositionCB={props.updatePositionCB} positions={props.positions} />
                        <ContractTabFC  key={s+'put'} strike={s} contractData={props.chain[s]['put']} updatePositionCB={props.updatePositionCB} positions={props.positions} />
                        </div>
                    )
                })}
            {/* </div> */}
            {/* <div className="puts-container col">
                {Object.keys(props.chain).map((s)=><ContractTabFC key={s} strike={s} contractData={props.chain[s]['put']} updatePositionCB={props.updatePositionCB} positions={props.positions} />)}
            </div> */}
        </div>
    </div>
}



export default function OptionChain(props) {
    //props.onPositionModified: (Array<OptionPosition>)=>any
    let testSymbol = "TSLA" // debug testing

    const [optionChainJson, setOptionChainJson] = useState({chainData:{}});
    const [positions, setPositions] = useState({});
    const [isLoadingOptChain, setIsLoadingOptChain] = useState(false)

    let updatePosition =(id, contractData, sizeAdjustment) => {
        let size = (id in positions) ? positions[id].size + sizeAdjustment : sizeAdjustment;
        let position = {[id]:{contractData, size}}
        let updatedPositions = {...positions, ...position};
        if(updatedPositions[id].size===0) delete updatedPositions[id];
        setPositions(()=>updatedPositions);
    }

    let onPositionModified=()=>{
        let positionList = Object.keys(positions).map((i)=>({...positions[i],  underlyingData:optionChainJson.underlying}))
        if(props.onPositionModified!==undefined) props.onPositionModified(getLiveOptionPositionList(positionList));
    }

    let clearPositions=()=>{
        setPositions({});
    }
    
    let getOptionChain=(symbol)=> {
        symbol = document.getElementById("symbolInput").value; //todo: replace with form and submit button
        setIsLoadingOptChain(true)
        tda.getOptionChainData(symbol, "100").then((data)=>{
            setOptionChainJson(data);
            setIsLoadingOptChain(false)
        });
        // console.log(optionChainJson);

    }

    let getSymbolLoadButton=()=>{
        return <button className="btn btn-primary" onClick={getOptionChain}>
        {!isLoadingOptChain?
            <>Go</>
            :
            <div className="d-flex justify-content-center">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            </div>}
    </button>
    }

    useEffect(onPositionModified,[positions]);

    return <div className="option-chain-ui-wrapper container ">
        <div className='flexCentered'>
            <input id="symbolInput" placeholder='symbol' type="text"/>
            {getSymbolLoadButton()}
            <button className="btn btn-primary" onClick={clearPositions}>Clear Positions</button>
        </div>
        {/* <List2 items={Object.keys(optionChainJson.chainData)}/> */}
        <div className="expDate-container row flex-nowrap overflow-auto">
            {Object.keys(optionChainJson.chainData).map((i)=><ExpDateChainFC className="col" key={optionChainJson.chainData[i].symbol} expDate={i} chain={optionChainJson.chainData[i]} positions={positions} updatePositionCB={updatePosition}/>)}
        </div>
    </div>
}

