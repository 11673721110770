//030621
// Refactor Test Page
// This will contain the component to render the testing workspace for the 030621 refactor
import v4 from 'uuid/dist/v4'

import {ModularContentObj, ContentObj, GridItem} from './modularcontent/ModularContentObj'
import {ContentGrid } from './modularcontent/ContentGrid';
import {StateArray, StorableStateArray} from './state arrays/StateArrays'

import {getTestModularContent} from '../_refactor030621/_temp/testComponents'

const uuid = v4;

export function RefactorTestPage2() {

    const gridConfig = {cols:20, rowHeight:100, width:1800};
    const defaultGridItemConfig = {x: 0, y: 0, w: 4, h: 3, minH: 2, maxH: 10, minW:4, static: false};
    
    const stateArray = new StorableStateArray({id:'storableStateArrayTest'});
    stateArray.callbacks.getNewItem=getNewGridItem;
    
    function getNewGridItem() {
        // test item to represent a grid item
        const modularContent = getTestModularContent();
        return new GridItem({gridConfig,gridItemConfig:defaultGridItemConfig,content:modularContent});
    }

    return (<>
        <h3>Refactor Workspace *2*</h3>
        <p>Currently testing reworking ContentGrid. </p>
        <ContentGrid stateArray={stateArray} gridConfig={gridConfig}/>
    </>);

} 



