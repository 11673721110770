//022121
// NavBar component


export default function Navbar({contentArray, currentContent, setCurrentContent}) {

    function getNavBarTabsFromContent() {
        if(contentArray==undefined) return (<></>);
        return (
            contentArray.map((i)=>{ 
                let isCurrent = i==currentContent;
                return(
                    <li className="nav-item" key={i} >
                    {
                        i==="|"? //if content divider is used, don't include onClick method
                        <a className="nav-link" id={i} href="#">{i}</a>
                        :
                        <a className="nav-link" id={i} href="#" onClick={(e)=>setCurrentContent(e.target.id)}>{i}</a>
                        
                    }
                    </li>

                )
            })
        )
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <div className='row d-flex justify-content-center'>
                    <a className="navbar-brand" href="#">OpTrdr</a>
                    {/* <p>(Site under construction)</p> */}
                    
                </div>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {getNavBarTabsFromContent()}
                    </ul>
                </div>
            </div>
        </nav>
    )

}