//031021
// Module to contain description of Modular Content Object
import v4 from 'uuid/dist/v4'
import {ModularContentComponent} from './ModularContentComponent'

const uuid = v4;



export class ContentObj { 
    // Represents Content that can be passed around and consructed as needed.

    constructor({id, component, title, props, state, widgets }) {
        this.id= (id==undefined) ? uuid() :id; //TODO: update to contentId
        this.component=component; // should be component func namme
        this.title=title;
        this.props=props;
        this.state=state;
        this.widgets=widgets; //optional container for additiona content objects. should be json/dict.
    }
}

export class ModularContentObj extends ContentObj {
    //constructs and returns a ModularContentObj 
    constructor({id, title, contentOptionsObj}) {
        super({id, component:ModularContentComponent, title});
        this.state={contentOptionsObj, activeContentKey:Object.keys(contentOptionsObj)[0]}
    }
}

export class GridItem { //todo: extract to own module

    constructor({id, title, gridItemConfig, gridConfig, content }) {
        this.id=(id==undefined) ? uuid() :id;
        this.title=title;
        this.gridItemConfig=gridItemConfig;
        this.gridConfig=gridConfig;
        this.content=content;
    }
}