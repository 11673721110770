//103121 (forked from DynamicStudy.js)
// Dynamic chart component.
//  -playing with the idea of consolidating charts into a single component.. the parent of the chart will 
//      pass through the necessary data and chart contents.

//021821
// Hijacking this to test charts that can have user-defined x and y axes

import {useEffect, useLayoutEffect, useState, useRef} from 'react'
import GridLayout from 'react-grid-layout';
import {Responsive as ResponsiveGridLayout} from 'react-grid-layout';
import v4 from 'uuid/dist/v4'

import OptionPositionList from '../OptionPositionList'
import OptionPosition from '../../classes/Option'
import DynamicGraph from '../graphs/DynamicGraph'
import graphTypes from '../../util/dataUtil'
import OptionChain from './OptionChain'

 const uuid = v4;

export default function LiveDynamicStudy(props) {

    const axisOptions = { //alternative approaches: can use static methods, or use eval? //TODO: make these items full objects to allow for more data. e.g. {displayName,propStr,func} and extra data about how to init the graph, like what middle number to use for x axis, and whether to invert the direction of the numbers(i.e. for xaxis=DTE)
    'OptionValue': (opt, args)=>opt.getPremium(args),
    'Intrinsic Value': (opt, args)=>opt.getIntrinsicValue(args),
    'Extrinisic Value': (opt, args)=>opt.getExtrinsicValue(args),
    'PL': (opt, args)=>opt.getPL(args),
    'PL at Expiration': (opt, args)=>opt.getPLAtExpiration(args),
    'daysToExpiration': (opt, args)=>opt.daysToExpiration,
    'volatility': (opt, args)=>opt.volatility,
    'riskFreeRate' : (opt, args)=>opt.riskFreeRate,
    'underlyingPrice': (opt, args)=>opt.underlyingPrice,
    'strike': (opt, args) =>opt.strike,
    'Credit/Debit': (opt, args) =>opt.getCreditDebit(args),
    'Delta: OptionValue': (opt, args) =>opt.getDelta(args),
    'Gamma: OptionValue': (opt, args) =>opt.getGamma(args),
    'Theta: OptionValue': (opt, args) =>opt.getTheta(args),
    'Vega: OptionValue': (opt, args) =>opt.getVega(args),
    'Rho: OptionValue': (opt, args) =>opt.getRho(args),
    'Delta: PositionValue': (opt, args) =>opt.getDelta(args, undefined, true),
    'Gamma: PositionValue': (opt, args) =>opt.getGamma(args, undefined, true),
    'Theta: PositionValue': (opt, args) =>opt.getTheta(args, undefined, true),
    'Vega: PositionValue': (opt, args) =>opt.getVega(args, undefined, true),
    'Rho: PositionValue': (opt, args) =>opt.getRho(args, undefined, true),
    'Max Profit': (opt, args) =>opt.getMaxProfit(args),
    'Max Loss': (opt, args) =>opt.getMaxLoss(args),
    'Expected Value': (opt, args) =>opt.getExpectedValue(args),
    'Prob ITM': (opt, args) =>opt.getProbITM(args),
    'Prob OTM': (opt, args) =>opt.getProbOTM(args),
    'Breakeven': (opt, args) =>opt.getUnderlyingPriceAtBreakeven(args),
    'POP': (opt, args) =>opt.getProbabilityOfProfit(args)
}

    const [positions, setPositions] = useState((props.positions===undefined) ? [] : props.positions);
    const [gridState, setGridState] = useState({nCols: 20, rowHeight: 100, width: 1800})
    const [graphs, setGraphs] = useState([getNewGraph('custom'), getNewGraph('pl')]); //getNewGraph('custom')
    const [idIincrementer, setIdIncrementer] = useState(0);
    const [showList,setShowList] = useState(true)
    
    //useEffect(onInit,[])
    //useEffect(onGraphsModified,[graphs])
    useEffect(refreshAllGraphData,[positions])

    console.log("LiveDynamicStudy>positions>",positions);

    function onGraphsModified() {
        saveToLocal();
        console.log("onGraphsModified > ", graphs);
    }

    function onInit() {
        loadFromLocal();
        let loaded = false;//loadFromLocal();
        if(!loaded) {
            // addNewGraph("pl");  
            // addNewGraph("compositeValue");  
            // addNewGraph("custom");
            //saveToLocal();
        }
    }

    function getNewGraph(graphType="custom") {
        const width = 4;
        const height = 3;
        const newGraph = {
            id: uuid(),
            axisOptions:axisOptions, 
            activeAxis: {x: 'underlyingPrice',y: 'PL at Expiration', z:null },
            data: [],
            graphType: graphTypes[graphType],
            graphControlSettings: {bounds: 20, granularity: 1, reverseData: false},
            gridLayout: {x: 0, y: 0, w: width, h: height, minH: 2, maxH: 10, minW:5, static: false},
            gridState: gridState
        }
        newGraph.data = newGraph.graphType.getData(positions, newGraph);
        return newGraph;
    }

    function addNewGraph(graphType="custom") {
        const newGraphs = graphs;
        newGraphs.push(getNewGraph(graphType));
        setGraphs(newGraphs);
        //saveToLocal();
        setIdIncrementer(()=>idIincrementer+1) // not sure why yet, but as of now, you need this to make sure a new graph is rendered on the correct frame; else it suffers from late render.
    }

    const updateGraph = (updatedGraphState, remove=false) => {
        // this is passed to each DynamicGraph component as a callback for updates. 
        // They send their new state to this via the updatedGraphState input, then we find the matching graph in state.graphs and replace it with this one.

        const newGraphs = remove ? 
            graphs.filter((i) => {if(i.id!=updatedGraphState.id) return i}) :
            graphs.map((i) => {
                if(i.id===updatedGraphState.id) {
                    return {...updatedGraphState, data: updatedGraphState.graphType.getData(positions,updatedGraphState)};
                }
                return i;
            })
        //console.log("updateGraph> setGraphs");
        setGraphs(newGraphs); 
    }

    function udpateGraphPropsById(id, newProps) {
        const newGraphs = graphs.map((i) => {
            if(i.id===id) {
                return {...i, ...newProps};
            }
            return i;
        })
        //console.log("updateGraph> setGraphs");
        setGraphs(newGraphs); 
    }

    function updateGraphsLayouts(newLayoutArray) {
        // takes layout array (ie. from OnResize) and updates all graphStates //todo: this is probs going to cause a rerender of every graph. need to redesign.
        const newGraphs = [];
        //newGraphs.map(graph=>{return{...graph,gridLayout:{...newLayoutArray.filter(layout=>(layout.i==graph.id)?layout:null)}}}) //gross.
        for(let graph of graphs) {
            let layout = newLayoutArray.filter(layout=>(layout.i===graph.id)?layout:null)[0];
            newGraphs.push({...graph,gridLayout:layout});
        }
        setGraphs(newGraphs);
    }

    function refreshAllGraphData() {
        if(graphs.length==0) return;
        const newGraphs = graphs.map((i)=> {return {...i, data: i.graphType.getData(positions,i)}})
        //console.log("refreshAllGraphData> setGraphs");
        setGraphs(newGraphs);
    }

    function saveToLocal() {
        let graphsToSave = graphs.map((i)=>{return {...i, data:[]}}) // remove data when saving
        let jsonStr = JSON.stringify(graphsToSave);
        localStorage.setItem('dynamicStudyGraphs', jsonStr);
        console.log("GRAPHS SAVED > ", jsonStr);
    }

    function loadFromLocal() {

        let newGraphs = [];
        let loadedGraphs = JSON.parse(localStorage.getItem('dynamicStudyGraphs'));
        if(loadedGraphs!=null) { //loadedGraphs=[getNewGraph('custom'), getNewGraph('compositeValue'), getNewGraph('pl')];
            for(let g of loadedGraphs) {
                g.axisOptions=axisOptions;
                g.graphType=graphTypes[g.graphType.name];
                newGraphs.push(g);
             }
        }
        console.log("loadFromLocal", newGraphs)
        setGraphs(newGraphs);
    }

    function getLayout() {
        // returns grid layout json
        return graphs.map((i)=>{return {...i.gridLayout, i: i.id}});
    }

    function getPositionListToggleButton() {

        //console.log("getPositionList>",positions);
        let nPositions = positions.length;
        
        let positionSelectedText = nPositions + " positions selected";

        return  ( 
        <div class="accordion" id="positionListAccordion">
            <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {positionSelectedText}
            </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#positionListAccordion">
                <div class="accordion-body">
                    <OptionPositionList listId="liveDynamicStudyPositionsList" positions={positions} onPositionsUpdateCallback={setPositions}/>
                </div>
            </div>
        </div>
      </div>)
    }

    return (
        <div className="">
            <div className=''>
            <OptionChain className='' onPositionModified={setPositions}/>
            {/*console.log("DynamicStudy.return > graphs", graphs)*/}
            {/* <OptionPositionList listId="liveDynamicStudyPositionsList" positions={positions} onPositionsUpdateCallback={setPositions}/> */}
            <br/>
            <div>{getPositionListToggleButton()}</div>
            
            <button className="btn btn-outline-primary" onClick={()=>addNewGraph()}>New Graph</button>
            <GridLayout className="layout mb-5"
                cols={gridState.nCols} rowHeight={gridState.rowHeight} width={gridState.width} 
                //onResizeStop={(layout)=>{udpateGraphPropsById(layout[0].i,{gridLayout: layout[0]}); console.log(layout);}}
                onResizeStop={(layout)=>{updateGraphsLayouts(layout); console.log(layout);}}
                verticalCompact={true}
                compactType='vertical'
                draggableCancel='.preventDrag'>
                {graphs.map((i)=> {
                    // console.log(i)
                    return <div className="border rounded-3" id={i.id} key={i.id} data-grid={i.gridLayout} disabled={i.gridLayout.static}> <DynamicGraph positions={positions} graphState={i} updateGraphCallback={updateGraph}/> </div>
            })}
            
            </GridLayout>
            

            {/* <ResponsiveGridLayout 
                className="layout" 
                verticalCompact={false}
                onResizeStop={(layout)=>udpateGraphPropsById(layout[0].i,{gridLayout: layout[0]})}
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>

                {graphs.map((i)=>
                    <div className="border rounded-3" key={i.id} data-grid={i.gridLayout}> <DynamicGraph positions={positions} graphState={i} updateGraphCallback={updateGraph}/> </div>
                )}

            </ResponsiveGridLayout> */}
            </div>
        </div>
    )
}

