//031321
//ModularContentComponent
//  - Takes contentOptions
//  - Displays ModularContentNavbar
//  - Displays Content

import {useState} from 'react'
import {ModularContentNavbar} from './ModularContentNavBar'

export function ModularContentComponent({id, state, setState}) {

    const contentOptionsObj = state.contentOptionsObj;
    const activeContentKey = state.activeContentKey;
    const activeContent = contentOptionsObj[activeContentKey];
    if(activeContent==null) return (<div>No Content Available</div>);
    const ActiveComponent = activeContent.component; // need to assign to variable(which must begin upperCase) in order to use as a react component in jsx.
    const widgets = activeContent.widgets;

    function setActiveContentKey(newKey) {
        setState({...state, activeContentKey:newKey});
    }

    function updateActiveContentState({stateKey, newValue}) {
        setState({...state, contentOptionsObj:{...state.contentOptionsObj, [activeContentKey]:{...activeContent, [stateKey]:newValue}}});
    }

    function setActiveContentState(newState) {
        setState({...state, contentOptionsObj:{...state.contentOptionsObj, [activeContentKey]:newState}});
    }

    function setWidgetContentState(widgetContentKey, newState) {
        setState({...state, contentOptionsObj:{...state.contentOptionsObj, [activeContentKey]:{...activeContent, widgets:{...widgets,[widgetContentKey]:{...widgets[widgetContentKey], state:newState}}}}});
    }

    return (
    <div>
        <ModularContentNavbar id={id} contentOptionsObj={contentOptionsObj} activeContentKey={activeContentKey} onContentSelected={setActiveContentKey} setWidgetState={setWidgetContentState}/>
        <ActiveComponent {...activeContent.props} {...activeContent} setState={setActiveContentState}/>
    </div>)
}