//020721
// Option Positions List component

import {useState, useRef, useEffect} from 'react'
import OptionPosition from '../classes/Option'
import OptionPositionComponent from './OptionPositionComponent'
import MultiOptionPosition from '../classes/MultiOptionPosition'
import './OptionPositionList.css'

function OptionPositionList({listId, onPositionsUpdateCallback, positions}) {
  // Component that manages a list of OptionPositions
  // passPositions is a method to to pass positions upward
  // listId is used as an identifier to store/load positions in localStorage.

  const [positionsObj, setPositionsObj] = useState(new MultiOptionPosition(listId, [], onPositionsModified));

  if(positions!==undefined) positionsObj.setPositions(positions)

  useEffect(()=>onInit(), []) // init

  function onInit() {
    positionsObj.loadFromLocal()
  }

  function onPositionsModified() {
    onPositionsUpdateCallback(positionsObj.positions);
    positionsObj.saveToLocal();
  }


  function getNetCreditDebitFormatted() {
    // returns formatted text to display net credit/debit
    let amt = positionsObj.getNetCreditDebitAmt();
    let text = (amt<0) ? "Debit" : "Credit";
    return "Net "+ text + ": " + amt;
  }

  function getNetPositionInfoElement() {
    // returns the net position info to display at the bottom of the list
    const creditDebit = getNetCreditDebitFormatted();
    const netGreeks = positionsObj.getNetGreeks(true)
    return (
    <div className="container">
      <ul className="list-inline row bg-light bg-gradient">
        <li className="list-inline-item col">{creditDebit}</li>
        <li className="col-3"></li>
        {Object.keys(netGreeks).map((k)=>{
          return <li key={k} className="list-inline-item col">{k}: {netGreeks[k].toFixed(3)}</li>
        })}
      </ul>
    </div>
    )
  }

  return (
      <div className="m-2">
        <div className="table-responsive p-2">
          <div className="container-fluid p-2">
              {positionsObj.positions.map((pos) => (
                  <>
                  <OptionPositionComponent 
                    key={pos.id} 
                    position={pos}
                    updatePosition={positionsObj.updatePosition}
                    removePositionById={positionsObj.removePositionById} />
                  </>
                  ))}
              
            </div>
          <br/>
          {getNetPositionInfoElement()}
          <br/>
          </div>
        <button className="btn btn-outline-primary" onClick={positionsObj.addNewPosition}>Add Position</button>
        
        <button className="btn btn-outline-secondary" onClick={positionsObj.resetList}>Reset Positions</button>
        <br/>
      </div>
  )
}

export default OptionPositionList;