/* 020621
Option PL Graph
*/
import { useState } from 'react';
import { ResponsiveContainer, Area, ComposedChart,ReferenceLine, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import GraphControlsUI from './GraphControlsUI'
//import getPLData from '../util/dataUtil'

// const data = [
//   { label: 'January', sales: 21, leads: 41 },
//   { label: 'February', sales: 35, leads: 79 },
//   { label: 'March', sales: 75, leads: 57 },
//   { label: 'April', sales: 51, leads: 47 },
//   { label: 'May', sales: 41, leads: 63 },
//   { label: 'June', sales: 47, leads: 71 }
// ];


// function getPLData_old(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10) {
//     // takes array of objects that include {strike, netPremium, optionType, optionDirection}
//     if(optionsData.length==0) return [];
//     const data = []
//     const lowestStrike = parseFloat(optionsData.reduce((min, o) => o.strike < min ? o.strike : min, optionsData[0].strike));
//     const highestStrike = parseFloat(optionsData.reduce((max, o) => o.strike > max ? o.strike : max, optionsData[0].strike));

//     for(var i=lowestStrike-relLowerBound; i<highestStrike+relUpperBound; i+=dataIncrements) {
//         var cumPl = 0;
//         for(let opt of optionsData) {
//             let intrinsicValue = opt.getIntrinsicValue({underlyingPrice: i})
//             let creditDebit = opt.getCreditDebit();
//             let pl = (opt.buySell=="buy") ? intrinsicValue + creditDebit: -intrinsicValue + creditDebit; //TODO: tidy this; ssee if there is a better way to get this from OptionPosition obj. Copying from there now to save time.
//             cumPl+= pl;
//         }
//         var profitRng = cumPl>=0 ? [0,cumPl] : []
//         var lossRng = cumPl<=0 ? [cumPl, 0] : []
//         data.push({underlyingPriceAtExpiry: i, pl: cumPl, profitRng, lossRng})
//     }
//     return data;
// }

function getPLData(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10) {
  // takes array of objects that include {strike, netPremium, optionType, optionDirection}
  if(optionsData.length==0) return [];
  const data = []
  const lowestStrike = parseFloat(optionsData.reduce((min, o) => o.strike < min ? o.strike : min, optionsData[0].strike));
  const highestStrike = parseFloat(optionsData.reduce((max, o) => o.strike > max ? o.strike : max, optionsData[0].strike));

  for(var i=lowestStrike-relLowerBound; i<highestStrike+relUpperBound; i+=dataIncrements) {
      var cumPl = 0;
      for(let opt of optionsData) {
          cumPl += opt.getPLAtExpiration({underlyingPrice:i});
      }
      var profitRng = cumPl>=0 ? [0,cumPl] : []
      var lossRng = cumPl<=0 ? [cumPl, 0] : []
      data.push({underlyingPriceAtExpiry: i, pl: cumPl, profitRng, lossRng})
  }
  return data;
}


function PLGraph({optionPositions}) {

  const [graphControlSettings,setGraphControlSettings] = useState({bounds: 15, granularity: 0.5});

  const data = getPLData(optionPositions, graphControlSettings.granularity, graphControlSettings.bounds, graphControlSettings.bounds);


  return (
    <div className="row">
      {/*JSON.stringify(optionPositions)*/}
      <div className="section col-md-6">
        <h3 className="section-title">P/L at Expiration:</h3>
        <div className="section-content">
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="underlyingPriceAtExpiry" name="Underlying's Price (at Expiry)"/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0}/>
              <ReferenceLine y={0} stroke="green" strokeDasharray="3 3" />              
              <Line type="monotone" dataKey="pl" name="P/L At Expiry" stroke="#428bca" strokeWidth={2} dot={false} />
              <Area type="monotone" dataKey="profitRng" name="Profit" stroke="none" fill="#5cb85c" fillOpacity={0.25} />
              <Area type="monotone" dataKey="lossRng" name="Loss" stroke="none" fill="#d9534f" fillOpacity={0.25} />
            
            </ComposedChart>
          </ResponsiveContainer>

          <GraphControlsUI graphControlSettings={graphControlSettings} settingsModifiedCallback={setGraphControlSettings}/>

        </div>
      </div>
    </div>
  )
}

export default PLGraph;