//030621
// Refactor Test Page
// This will contain the component to render the testing workspace for the 030621 refactor

//import {GraphComponent} from './graphs/GraphComponent'

import {GraphBaseComponent, getTestGraphContentObj} from './_temp/graphOverhaulWorkspace'


let testGraphContentObj = getTestGraphContentObj();

export function RefactorTestPage({}) {


    return (<>
        <h3>This is the Refactor Workspace</h3>
        <p>Currently working on building new graph components</p>

        <ContentComponent contentObj={testGraphContentObj}/>
    </>);

} 

function ContentComponent({contentObj, setState}){ // 
    // renders the provided contentObj
    let Component = contentObj.component;
    return <Component {...contentObj.props} {...contentObj} setState={setState} />
}