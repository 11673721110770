//020721
// Will graph price of an option over multiple strike prices

import { useState } from 'react';
import { ResponsiveContainer, ReferenceLine, LineChart, Line, Area, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ComposedChart } from 'recharts';
import GraphControlsUI from './GraphControlsUI'


function getValueData(optionsData, dataIncrements=0.5, relLowerBound=10, relUpperBound=10) {
  // takes array of objects that include {strike, netPremium, optionType, optionDirection}
  if(optionsData.length==0) return [];
  const data = []
  const lowestStrike = parseFloat(optionsData.reduce((min, o) => o.underlyingPrice < min ? o.underlyingPrice : min, optionsData[0].underlyingPrice));
  const highestStrike = parseFloat(optionsData.reduce((max, o) => o.underlyingPrice > max ? o.underlyingPrice : max, optionsData[0].underlyingPrice));

  for(var i=lowestStrike-Math.abs(relLowerBound); i<highestStrike+Math.abs(relUpperBound); i+=dataIncrements) {

      var cumPremium = 0;
      var cumIntrinsic = 0;
      var cumExtrinsic = 0;
      for(let opt of optionsData) {
        cumPremium += opt.getPremium({strike: i});
        cumIntrinsic += opt.getIntrinsicValue({strike: i});
        cumExtrinsic += opt.getExtrinsicValue({strike: i});
      }
      var intrinsicRng = cumIntrinsic>=0 ? [0, cumIntrinsic] : [];
      var extrinsicRng = [cumIntrinsic, cumPremium]
      data.push({strike: i, cumPremium, intrinsicRng, extrinsicRng, cumExtrinsic});
  }
  return data;
}

function CompositeValueGraph({optionPositions}) {

  const [graphControlSettings,setGraphControlSettings] = useState({bounds: 15, granularity: 0.5});

  const data = getValueData(optionPositions, graphControlSettings.granularity, graphControlSettings.bounds, graphControlSettings.bounds);

  return (
    <div className="row">
      {/*JSON.stringify(optionPositions)*/}
      <div className="section col-md-10">
        <h3 className="section-title">Total Value:</h3>
        <div className="section-content">

          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 0 }}>
              <Tooltip />
              <XAxis dataKey="strike" name="Strike" label={()=>"Strike"}/>
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Legend verticalAlign="bottom" height={0} margin={{top:20}}/>
              <Area type="monotone" dataKey="extrinsicRng" name="Extrinsic Value" stroke="#107dac" fill="#107dac" fillOpacity={0.25}/>
              <Area type="monotone" dataKey="intrinsicRng" name="Intrinsic Value" stroke="#5cb85c" fill="#5cb85c" fillOpacity={0.25}/>
              <Line type="monotone" dataKey="cumPremium" name="Option Value At Strike" stroke="#FB8833" strokeWidth={2} dot={false}/>
            </ComposedChart>
          </ResponsiveContainer>

          <GraphControlsUI graphControlSettings={graphControlSettings} settingsModifiedCallback={setGraphControlSettings}/>
        </div>
      </div>
    </div>
  )
}

export default CompositeValueGraph;