
import './App.css';
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {OptionsApp} from './OptionsApp/OptionsApp'


function App() {

  const defaultPage = <OptionsApp/>

 return (
  // <h4>Test</h4>
  <BrowserRouter>
  
  {defaultPage} 
    <Routes >
      <Route path="/options" element={<OptionsApp />}/>
    </Routes>
</BrowserRouter>
 )
}


export default App;
